import { INaeProperty } from "@newageerp/v3.app.main-bundle";
export const NaeSProperties: INaeProperty[] = [
    {
        "schema": "ad-cost",
        "key": "country",
        "type": "string",
        "format": "",
        "title": "Country",
        "additionalProperties": [],
        "description": "",
        "className": "AdCost",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "ad-cost",
        "key": "date",
        "type": "string",
        "format": "date",
        "title": "Date",
        "additionalProperties": [],
        "description": "",
        "className": "AdCost",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "ad-cost",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "AdCost",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "ad-cost",
        "key": "language",
        "type": "string",
        "format": "",
        "title": "Language",
        "additionalProperties": [],
        "description": "",
        "className": "AdCost",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "ad-cost",
        "key": "source",
        "type": "string",
        "format": "",
        "title": "Source",
        "additionalProperties": [],
        "description": "",
        "className": "AdCost",
        "isDb": true,
        "dbType": "varchar",
        "enum": [
            {
                "label": "",
                "value": "",
                "color": "slate"
            },
            {
                "label": "Facebook",
                "value": "facebook",
                "color": "slate"
            },
            {
                "label": "Google",
                "value": "google",
                "color": "slate"
            },
            {
                "label": "Tiktok",
                "value": "tiktok",
                "color": "slate"
            }
        ],
        "naeType": "enum_text"
    },
    {
        "schema": "ad-cost",
        "key": "total",
        "type": "number",
        "format": "float",
        "title": "Total",
        "additionalProperties": [],
        "description": "",
        "className": "AdCost",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "adalo-user",
        "key": "adaloCreatedAt",
        "type": "string",
        "format": "date-time",
        "title": "Created At",
        "additionalProperties": [],
        "description": "",
        "className": "AdaloUser",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "adalo-user",
        "key": "adaloUpdatedAt",
        "type": "string",
        "format": "date-time",
        "title": "Updated At",
        "additionalProperties": [],
        "description": "",
        "className": "AdaloUser",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "adalo-user",
        "key": "completedLessons",
        "type": "array",
        "format": "string",
        "title": "Completed Lessons",
        "additionalProperties": [],
        "description": "",
        "className": "AdaloUser",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "adalo-user",
        "key": "completedLessonsCount",
        "type": "integer",
        "format": "",
        "title": "Completed Lessons (count)",
        "additionalProperties": [],
        "description": "",
        "className": "AdaloUser",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "adalo-user",
        "key": "createdAt",
        "type": "string",
        "format": "datetime",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "AdaloUser",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "adalo-user",
        "key": "creator",
        "type": "rel",
        "format": "user",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "AdaloUser",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "adalo-user",
        "key": "crmFinishedAt",
        "type": "string",
        "format": "date-time",
        "title": "CRM Cancelation date",
        "additionalProperties": [],
        "description": "",
        "className": "AdaloUser",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "adalo-user",
        "key": "doer",
        "type": "rel",
        "format": "user",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "AdaloUser",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "adalo-user",
        "key": "email",
        "type": "string",
        "format": "",
        "title": "Email",
        "additionalProperties": [],
        "description": "",
        "className": "AdaloUser",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "adalo-user",
        "key": "fullName",
        "type": "string",
        "format": "",
        "title": "Full name",
        "additionalProperties": [],
        "description": "",
        "className": "AdaloUser",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "adalo-user",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "AdaloUser",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "adalo-user",
        "key": "needSync",
        "type": "boolean",
        "format": "",
        "title": "needSync",
        "additionalProperties": [],
        "description": "",
        "className": "AdaloUser",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "adalo-user",
        "key": "nextLessonId",
        "type": "integer",
        "format": "",
        "title": "Next Lesson ID",
        "additionalProperties": [],
        "description": "",
        "className": "AdaloUser",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "adalo-user",
        "key": "nextvailableLessonDate",
        "type": "string",
        "format": "date-time",
        "title": "Next Available Lesson Date",
        "additionalProperties": [],
        "description": "",
        "className": "AdaloUser",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "adalo-user",
        "key": "notFoundInCrm",
        "type": "boolean",
        "format": "",
        "title": "notFoundInCrm",
        "additionalProperties": [],
        "description": "",
        "className": "AdaloUser",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "adalo-user",
        "key": "refunds",
        "type": "number",
        "format": "float",
        "title": "Refunds",
        "additionalProperties": [],
        "description": "",
        "className": "AdaloUser",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "adalo-user",
        "key": "showFavouritesFunc",
        "type": "boolean",
        "format": "",
        "title": "Show Favorites Functionality",
        "additionalProperties": [],
        "description": "",
        "className": "AdaloUser",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "adalo-user",
        "key": "showNewVersion",
        "type": "boolean",
        "format": "",
        "title": "Show New Version",
        "additionalProperties": [],
        "description": "",
        "className": "AdaloUser",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "adalo-user",
        "key": "skipValidation",
        "type": "boolean",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "AdaloUser",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "adalo-user",
        "key": "tag",
        "type": "string",
        "format": "",
        "title": "Tag",
        "additionalProperties": [],
        "description": "",
        "className": "AdaloUser",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "adalo-user",
        "key": "uniqueCode",
        "type": "string",
        "format": "",
        "title": "Unique Code",
        "additionalProperties": [],
        "description": "",
        "className": "AdaloUser",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "adalo-user",
        "key": "updatedAt",
        "type": "string",
        "format": "datetime",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "AdaloUser",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "analytics-views",
        "key": "adSource",
        "type": "string",
        "format": "",
        "title": "adSource",
        "additionalProperties": [],
        "description": "",
        "className": "AnalyticsViews",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "analytics-views",
        "key": "country",
        "type": "string",
        "format": "",
        "title": "country",
        "additionalProperties": [],
        "description": "",
        "className": "AnalyticsViews",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "analytics-views",
        "key": "date",
        "type": "string",
        "format": "date",
        "title": "date",
        "additionalProperties": [],
        "description": "",
        "className": "AnalyticsViews",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "analytics-views",
        "key": "funnel",
        "type": "string",
        "format": "",
        "title": "funnel",
        "additionalProperties": [],
        "description": "",
        "className": "AnalyticsViews",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "analytics-views",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "AnalyticsViews",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "analytics-views",
        "key": "lang",
        "type": "string",
        "format": "",
        "title": "lang",
        "additionalProperties": [],
        "description": "",
        "className": "AnalyticsViews",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "analytics-views",
        "key": "source",
        "type": "string",
        "format": "",
        "title": "source",
        "additionalProperties": [],
        "description": "",
        "className": "AnalyticsViews",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "analytics-views",
        "key": "total",
        "type": "number",
        "format": "float",
        "title": "total",
        "additionalProperties": [],
        "description": "",
        "className": "AnalyticsViews",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "analytics-views",
        "key": "type",
        "type": "string",
        "format": "",
        "title": "type",
        "additionalProperties": [],
        "description": "",
        "className": "AnalyticsViews",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "base-entity",
        "key": "createdAt",
        "type": "string",
        "format": "datetime",
        "title": "Sukurta",
        "additionalProperties": [],
        "description": "",
        "className": "BaseEntity",
        "isDb": false,
        "dbType": "",
        "naeType": "datetime"
    },
    {
        "schema": "base-entity",
        "key": "creator",
        "type": "rel",
        "format": "user",
        "title": "Sukūrėjas",
        "additionalProperties": [],
        "description": "",
        "className": "BaseEntity",
        "isDb": false,
        "dbType": "",
        "naeType": "object"
    },
    {
        "schema": "base-entity",
        "key": "doer",
        "type": "rel",
        "format": "user",
        "title": "Responsible person",
        "additionalProperties": [],
        "description": "",
        "className": "BaseEntity",
        "isDb": false,
        "dbType": "",
        "naeType": "object"
    },
    {
        "schema": "base-entity",
        "key": "skipValidation",
        "type": "boolean",
        "format": "",
        "title": "Get the value of skipValidation",
        "additionalProperties": [],
        "description": "",
        "className": "BaseEntity",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "base-entity",
        "key": "updatedAt",
        "type": "string",
        "format": "datetime",
        "title": "Atnaujinta",
        "additionalProperties": [],
        "description": "",
        "className": "BaseEntity",
        "isDb": false,
        "dbType": "",
        "naeType": "datetime"
    },
    {
        "schema": "country",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Country",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "country",
        "key": "name",
        "type": "string",
        "format": "",
        "title": "Name",
        "additionalProperties": [],
        "description": "",
        "className": "Country",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "country-group",
        "key": "countries",
        "type": "array",
        "format": "string",
        "title": "Include countries",
        "additionalProperties": [],
        "description": "",
        "className": "CountryGroup",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "country-group",
        "key": "countriesExclude",
        "type": "array",
        "format": "string",
        "title": "Exclude countries",
        "additionalProperties": [],
        "description": "",
        "className": "CountryGroup",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "country-group",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "CountryGroup",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "country-group",
        "key": "name",
        "type": "string",
        "format": "",
        "title": "Name",
        "additionalProperties": [],
        "description": "",
        "className": "CountryGroup",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "currency-rate",
        "key": "currencyFrom",
        "type": "string",
        "format": "",
        "title": "Get the value of currencyFrom",
        "additionalProperties": [],
        "description": "",
        "className": "CurrencyRate",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "currency-rate",
        "key": "currencyTo",
        "type": "string",
        "format": "",
        "title": "Get the value of currencyTo",
        "additionalProperties": [],
        "description": "",
        "className": "CurrencyRate",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "currency-rate",
        "key": "date",
        "type": "string",
        "format": "",
        "title": "Get the value of date",
        "additionalProperties": [],
        "description": "",
        "className": "CurrencyRate",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "currency-rate",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "CurrencyRate",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "currency-rate",
        "key": "rate",
        "type": "number",
        "format": "float",
        "title": "Get the value of rate",
        "additionalProperties": [],
        "description": "",
        "className": "CurrencyRate",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "customer",
        "key": "_ViewTitle",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Customer",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "customer",
        "key": "activeSubscriptionDate",
        "type": "string",
        "format": "date-time",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Customer",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "customer",
        "key": "activeSubscriptions",
        "type": "array",
        "format": "subscription",
        "title": "",
        "additionalProperties": [
            {
                "mapped": "customer"
            }
        ],
        "description": "",
        "className": "Customer",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "customer",
        "key": "adaloUser",
        "type": "rel",
        "format": "adalo-user",
        "title": "Adalo User",
        "additionalProperties": [],
        "description": "",
        "className": "Customer",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "customer",
        "key": "country",
        "type": "string",
        "format": "",
        "title": "Country",
        "additionalProperties": [],
        "description": "",
        "className": "Customer",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "customer",
        "key": "createdAt",
        "type": "string",
        "format": "datetime",
        "title": "Created At",
        "additionalProperties": [],
        "description": "",
        "className": "Customer",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "customer",
        "key": "creator",
        "type": "rel",
        "format": "user",
        "title": "creator",
        "additionalProperties": [],
        "description": "",
        "className": "Customer",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "customer",
        "key": "doer",
        "type": "rel",
        "format": "user",
        "title": "doer",
        "additionalProperties": [],
        "description": "",
        "className": "Customer",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "customer",
        "key": "email",
        "type": "string",
        "format": "",
        "title": "Email",
        "additionalProperties": [],
        "description": "",
        "className": "Customer",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "customer",
        "key": "externalId",
        "type": "string",
        "format": "",
        "title": "externalId",
        "additionalProperties": [],
        "description": "",
        "className": "Customer",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "customer",
        "key": "funnel",
        "type": "string",
        "format": "",
        "title": "Funnel",
        "additionalProperties": [],
        "description": "",
        "className": "Customer",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "customer",
        "key": "gender",
        "type": "string",
        "format": "",
        "title": "Gender",
        "additionalProperties": [],
        "description": "",
        "className": "Customer",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "customer",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Customer",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "customer",
        "key": "ip",
        "type": "string",
        "format": "",
        "title": "IP",
        "additionalProperties": [],
        "description": "",
        "className": "Customer",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "customer",
        "key": "language",
        "type": "string",
        "format": "",
        "title": "Language",
        "additionalProperties": [],
        "description": "",
        "className": "Customer",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "customer",
        "key": "lastPaymentDate",
        "type": "string",
        "format": "date-time",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Customer",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "customer",
        "key": "lastSubscription",
        "type": "rel",
        "format": "subscription",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Customer",
        "isDb": false,
        "dbType": "",
        "naeType": "object"
    },
    {
        "schema": "customer",
        "key": "lifetimeSubscriptions",
        "type": "array",
        "format": "subscription",
        "title": "",
        "additionalProperties": [
            {
                "mapped": "customer"
            }
        ],
        "description": "",
        "className": "Customer",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "customer",
        "key": "nextSubscriptionPayment",
        "type": "string",
        "format": "date-time",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Customer",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "customer",
        "key": "payments",
        "type": "array",
        "format": "payment",
        "title": "Payments",
        "additionalProperties": [
            {
                "mapped": "customer"
            }
        ],
        "description": "",
        "className": "Customer",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "customer",
        "key": "paymentsTotal",
        "type": "number",
        "format": "float",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Customer",
        "isDb": false,
        "dbType": "",
        "naeType": "float"
    },
    {
        "schema": "customer",
        "key": "quizResults",
        "type": "array",
        "format": "quiz-result",
        "title": "Quiz Results",
        "additionalProperties": [
            {
                "mapped": "customer"
            }
        ],
        "description": "",
        "className": "Customer",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "customer",
        "key": "scopes",
        "type": "array",
        "format": "string",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Customer",
        "isDb": false,
        "dbType": "",
        "naeType": "string_array"
    },
    {
        "schema": "customer",
        "key": "secondaryEmail",
        "type": "string",
        "format": "",
        "title": "secondaryEmail",
        "additionalProperties": [],
        "description": "",
        "className": "Customer",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "customer",
        "key": "skipValidation",
        "type": "boolean",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Customer",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "customer",
        "key": "subscriptions",
        "type": "array",
        "format": "subscription",
        "title": "Subscriptions",
        "additionalProperties": [
            {
                "mapped": "customer"
            }
        ],
        "description": "",
        "className": "Customer",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "customer",
        "key": "testUser",
        "type": "boolean",
        "format": "",
        "title": "Test user",
        "additionalProperties": [],
        "description": "",
        "className": "Customer",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "customer",
        "key": "updatedAt",
        "type": "string",
        "format": "datetime",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Customer",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "customer",
        "key": "upsellUrl",
        "type": "string",
        "format": "",
        "title": "Upsell URL",
        "additionalProperties": [],
        "description": "",
        "className": "Customer",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "file",
        "key": "appproved",
        "type": "boolean",
        "format": "",
        "title": "Get the value of appproved",
        "additionalProperties": [],
        "description": "",
        "className": "File",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "file",
        "key": "createdAt",
        "type": "string",
        "format": "datetime",
        "title": "Sukurta",
        "additionalProperties": [],
        "description": "",
        "className": "File",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "file",
        "key": "creator",
        "type": "rel",
        "format": "user",
        "title": "Sukūrėjas",
        "additionalProperties": [],
        "description": "",
        "className": "File",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "file",
        "key": "deleted",
        "type": "boolean",
        "format": "",
        "title": "Get the value of deleted",
        "additionalProperties": [],
        "description": "",
        "className": "File",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "file",
        "key": "doer",
        "type": "rel",
        "format": "user",
        "title": "Responsible person",
        "additionalProperties": [],
        "description": "",
        "className": "File",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "file",
        "key": "fileName",
        "type": "string",
        "format": "",
        "title": "Get the value of fileName",
        "additionalProperties": [],
        "description": "",
        "className": "File",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "file",
        "key": "folder",
        "type": "string",
        "format": "",
        "title": "Get the value of folder",
        "additionalProperties": [],
        "description": "",
        "className": "File",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "file",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "File",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "file",
        "key": "orgFileName",
        "type": "string",
        "format": "",
        "title": "Get the value of orgFileName",
        "additionalProperties": [],
        "description": "",
        "className": "File",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "file",
        "key": "path",
        "type": "string",
        "format": "",
        "title": "Get the value of path",
        "additionalProperties": [],
        "description": "",
        "className": "File",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "file",
        "key": "skipValidation",
        "type": "boolean",
        "format": "",
        "title": "Get the value of skipValidation",
        "additionalProperties": [],
        "description": "",
        "className": "File",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "file",
        "key": "updatedAt",
        "type": "string",
        "format": "datetime",
        "title": "Atnaujinta",
        "additionalProperties": [],
        "description": "",
        "className": "File",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "frontend",
        "key": "_ViewTitle",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Frontend",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "frontend",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Frontend",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "frontend",
        "key": "lastSync",
        "type": "string",
        "format": "date-time",
        "title": "Last sync",
        "additionalProperties": [],
        "description": "",
        "className": "Frontend",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "frontend",
        "key": "title",
        "type": "string",
        "format": "",
        "title": "Title",
        "additionalProperties": [],
        "description": "",
        "className": "Frontend",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "frontend",
        "key": "url",
        "type": "string",
        "format": "",
        "title": "Url",
        "additionalProperties": [],
        "description": "",
        "className": "Frontend",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "klavyio-event-sync",
        "key": "createdAt",
        "type": "string",
        "format": "date-time",
        "title": "createdAt",
        "additionalProperties": [],
        "description": "",
        "className": "KlavyioEventSync",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "klavyio-event-sync",
        "key": "customerProperties",
        "type": "array",
        "format": "string",
        "title": "customerProperties",
        "additionalProperties": [],
        "description": "",
        "className": "KlavyioEventSync",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "klavyio-event-sync",
        "key": "email",
        "type": "string",
        "format": "",
        "title": "email",
        "additionalProperties": [],
        "description": "",
        "className": "KlavyioEventSync",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "klavyio-event-sync",
        "key": "eventName",
        "type": "string",
        "format": "",
        "title": "eventName",
        "additionalProperties": [],
        "description": "",
        "className": "KlavyioEventSync",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "klavyio-event-sync",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "KlavyioEventSync",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "klavyio-event-sync",
        "key": "properties",
        "type": "array",
        "format": "string",
        "title": "properties",
        "additionalProperties": [],
        "description": "",
        "className": "KlavyioEventSync",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "klavyio-event-sync",
        "key": "queueName",
        "type": "string",
        "format": "",
        "title": "queueName",
        "additionalProperties": [],
        "description": "",
        "className": "KlavyioEventSync",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "klavyio-event-sync",
        "key": "sentAt",
        "type": "string",
        "format": "date-time",
        "title": "sentAt",
        "additionalProperties": [],
        "description": "",
        "className": "KlavyioEventSync",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "klavyio-event-sync",
        "key": "status",
        "type": "integer",
        "format": "",
        "title": "status",
        "additionalProperties": [],
        "description": "",
        "className": "KlavyioEventSync",
        "isDb": true,
        "dbType": "int",
        "as": "status",
        "naeType": "status"
    },
    {
        "schema": "monitoring-item",
        "key": "adSpend",
        "type": "number",
        "format": "float",
        "title": "adSpend",
        "additionalProperties": [],
        "description": "",
        "className": "MonitoringItem",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "monitoring-item",
        "key": "aov",
        "type": "number",
        "format": "float",
        "title": "aov",
        "additionalProperties": [],
        "description": "",
        "className": "MonitoringItem",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "monitoring-item",
        "key": "cpa",
        "type": "number",
        "format": "float",
        "title": "cpa",
        "additionalProperties": [],
        "description": "",
        "className": "MonitoringItem",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "monitoring-item",
        "key": "hour",
        "type": "integer",
        "format": "",
        "title": "hour",
        "additionalProperties": [],
        "description": "",
        "className": "MonitoringItem",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "monitoring-item",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "MonitoringItem",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "monitoring-item",
        "key": "minutes",
        "type": "integer",
        "format": "",
        "title": "minutes",
        "additionalProperties": [],
        "description": "",
        "className": "MonitoringItem",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "monitoring-item",
        "key": "roas",
        "type": "number",
        "format": "float",
        "title": "roas",
        "additionalProperties": [],
        "description": "",
        "className": "MonitoringItem",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "monitoring-item",
        "key": "subs",
        "type": "integer",
        "format": "",
        "title": "subs",
        "additionalProperties": [],
        "description": "",
        "className": "MonitoringItem",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "monitoring-item",
        "key": "timeFormatted",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "MonitoringItem",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "monitoring-item",
        "key": "timezoneDate",
        "type": "string",
        "format": "date-time",
        "title": "timezoneDate",
        "additionalProperties": [],
        "description": "",
        "className": "MonitoringItem",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "monitoring-item",
        "key": "timezoneDateFormatted",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "MonitoringItem",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "note",
        "key": "content",
        "type": "string",
        "format": "text",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Note",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "note",
        "key": "createdAt",
        "type": "string",
        "format": "datetime",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Note",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "note",
        "key": "creator",
        "type": "rel",
        "format": "user",
        "title": "Get the value of creator",
        "additionalProperties": [],
        "description": "",
        "className": "Note",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "note",
        "key": "doer",
        "type": "rel",
        "format": "user",
        "title": "Get the value of doer",
        "additionalProperties": [],
        "description": "",
        "className": "Note",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "note",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Note",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "note",
        "key": "notify",
        "type": "array",
        "format": "number|string",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Note",
        "isDb": true,
        "dbType": "json",
        "naeType": "array"
    },
    {
        "schema": "note",
        "key": "notifyAccept",
        "type": "array",
        "format": "number|string",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Note",
        "isDb": true,
        "dbType": "json",
        "naeType": "array"
    },
    {
        "schema": "note",
        "key": "parentId",
        "type": "integer",
        "format": "",
        "title": "parentId",
        "additionalProperties": [],
        "description": "",
        "className": "Note",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "note",
        "key": "parentSchema",
        "type": "string",
        "format": "",
        "title": "parentSchema",
        "additionalProperties": [],
        "description": "",
        "className": "Note",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "note",
        "key": "replyTo",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Note",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "note",
        "key": "skipValidation",
        "type": "boolean",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Note",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "note",
        "key": "updatedAt",
        "type": "string",
        "format": "datetime",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Note",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "order-item",
        "key": "_viewTitle",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "OrderItem",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "order-item",
        "key": "customCancelEvent",
        "type": "string",
        "format": "",
        "title": "customCancelEvent",
        "additionalProperties": [],
        "description": "",
        "className": "OrderItem",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "order-item",
        "key": "customer",
        "type": "rel",
        "format": "customer",
        "title": "Customer",
        "additionalProperties": [],
        "description": "",
        "className": "OrderItem",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "order-item",
        "key": "customerRefId",
        "type": "string",
        "format": "",
        "title": "Ref ID",
        "additionalProperties": [],
        "description": "",
        "className": "OrderItem",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "order-item",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "OrderItem",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "order-item",
        "key": "price",
        "type": "number",
        "format": "float",
        "title": "Price",
        "additionalProperties": [],
        "description": "",
        "className": "OrderItem",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "order-item",
        "key": "quantity",
        "type": "number",
        "format": "float",
        "title": "Quantity",
        "additionalProperties": [],
        "description": "",
        "className": "OrderItem",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "order-item",
        "key": "referenceId",
        "type": "string",
        "format": "",
        "title": "Reference ID",
        "additionalProperties": [],
        "description": "",
        "className": "OrderItem",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "order-item",
        "key": "sku",
        "type": "string",
        "format": "",
        "title": "SKU",
        "additionalProperties": [],
        "description": "",
        "className": "OrderItem",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "order-item",
        "key": "subBilling",
        "type": "string",
        "format": "",
        "title": "Billing",
        "additionalProperties": [],
        "description": "",
        "className": "OrderItem",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "order-item",
        "key": "subTrial",
        "type": "string",
        "format": "",
        "title": "Trial",
        "additionalProperties": [],
        "description": "",
        "className": "OrderItem",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "order-item",
        "key": "title",
        "type": "string",
        "format": "",
        "title": "Title",
        "additionalProperties": [],
        "description": "",
        "className": "OrderItem",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "order-item",
        "key": "type",
        "type": "string",
        "format": "",
        "title": "Type",
        "additionalProperties": [],
        "description": "",
        "className": "OrderItem",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "order-item-sku-group",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "OrderItemSkuGroup",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "order-item-sku-group",
        "key": "sku",
        "type": "string",
        "format": "",
        "title": "SKU",
        "additionalProperties": [],
        "description": "",
        "className": "OrderItemSkuGroup",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "order-item-sku-group",
        "key": "sort",
        "type": "integer",
        "format": "",
        "title": "sort",
        "additionalProperties": [],
        "description": "",
        "className": "OrderItemSkuGroup",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "order-item-sku-group",
        "key": "title",
        "type": "string",
        "format": "",
        "title": "Group",
        "additionalProperties": [],
        "description": "",
        "className": "OrderItemSkuGroup",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "payment",
        "key": "_ViewTitle",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Payment",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "payment",
        "key": "chargeBack",
        "type": "boolean",
        "format": "",
        "title": "chargeBack",
        "additionalProperties": [],
        "description": "",
        "className": "Payment",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "payment",
        "key": "createdAt",
        "type": "string",
        "format": "datetime",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Payment",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "payment",
        "key": "creator",
        "type": "rel",
        "format": "user",
        "title": "creator",
        "additionalProperties": [],
        "description": "",
        "className": "Payment",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "payment",
        "key": "currency",
        "type": "string",
        "format": "",
        "title": "Get the value of currency",
        "additionalProperties": [],
        "description": "",
        "className": "Payment",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "payment",
        "key": "currencyRate",
        "type": "number",
        "format": "float",
        "title": "Get the value of currencyRate",
        "additionalProperties": [],
        "description": "",
        "className": "Payment",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "payment",
        "key": "customer",
        "type": "rel",
        "format": "customer",
        "title": "Customer",
        "additionalProperties": [],
        "description": "",
        "className": "Payment",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "payment",
        "key": "customerEmails",
        "type": "string",
        "format": "",
        "title": "Customer",
        "additionalProperties": [],
        "description": "",
        "className": "Payment",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "payment",
        "key": "customerProcessor",
        "type": "rel",
        "format": "customer",
        "title": "Customer (p)",
        "additionalProperties": [],
        "description": "",
        "className": "Payment",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "payment",
        "key": "date",
        "type": "string",
        "format": "date-time",
        "title": "Date",
        "additionalProperties": [],
        "description": "",
        "className": "Payment",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "payment",
        "key": "dateUsFormatted",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Payment",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "payment",
        "key": "description",
        "type": "string",
        "format": "text",
        "title": "Description",
        "additionalProperties": [],
        "description": "",
        "className": "Payment",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "payment",
        "key": "doer",
        "type": "rel",
        "format": "user",
        "title": "doer",
        "additionalProperties": [],
        "description": "",
        "className": "Payment",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "payment",
        "key": "fee",
        "type": "number",
        "format": "float",
        "title": "Fee",
        "additionalProperties": [],
        "description": "",
        "className": "Payment",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "payment",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Payment",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "payment",
        "key": "mainOrderId",
        "type": "string",
        "format": "",
        "title": "mainOrderId",
        "additionalProperties": [],
        "description": "",
        "className": "Payment",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "payment",
        "key": "orderId",
        "type": "string",
        "format": "",
        "title": "Order ID",
        "additionalProperties": [],
        "description": "",
        "className": "Payment",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "payment",
        "key": "orderIdFixed",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Payment",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "payment",
        "key": "orgTotal",
        "type": "number",
        "format": "float",
        "title": "Get the value of orgTotal",
        "additionalProperties": [],
        "description": "",
        "className": "Payment",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "payment",
        "key": "paymentCardInfo",
        "type": "rel",
        "format": "payment-card-info",
        "title": "paymentCardInfo",
        "additionalProperties": [],
        "description": "",
        "className": "Payment",
        "isDb": false,
        "dbType": "",
        "naeType": "object"
    },
    {
        "schema": "payment",
        "key": "paymentChargeInfo",
        "type": "rel",
        "format": "payment-charge-info",
        "title": "paymentChargeInfo",
        "additionalProperties": [],
        "description": "",
        "className": "Payment",
        "isDb": false,
        "dbType": "",
        "naeType": "object"
    },
    {
        "schema": "payment",
        "key": "paymentOrchestrator",
        "type": "rel",
        "format": "payment-orchestrator",
        "title": "paymentOrchestrator",
        "additionalProperties": [],
        "description": "",
        "className": "Payment",
        "isDb": false,
        "dbType": "",
        "naeType": "object"
    },
    {
        "schema": "payment",
        "key": "paymentPsp",
        "type": "rel",
        "format": "payment-psp",
        "title": "paymentPsp",
        "additionalProperties": [],
        "description": "",
        "className": "Payment",
        "isDb": false,
        "dbType": "",
        "naeType": "object"
    },
    {
        "schema": "payment",
        "key": "paymentRefundInfo",
        "type": "rel",
        "format": "payment-refund-info",
        "title": "paymentRefundInfo",
        "additionalProperties": [],
        "description": "",
        "className": "Payment",
        "isDb": false,
        "dbType": "",
        "naeType": "object"
    },
    {
        "schema": "payment",
        "key": "paymentTaxInfo",
        "type": "rel",
        "format": "payment-tax-info",
        "title": "paymentTaxInfo",
        "additionalProperties": [],
        "description": "",
        "className": "Payment",
        "isDb": false,
        "dbType": "",
        "naeType": "object"
    },
    {
        "schema": "payment",
        "key": "processorId",
        "type": "string",
        "format": "",
        "title": "ProcessorId",
        "additionalProperties": [],
        "description": "",
        "className": "Payment",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "payment",
        "key": "rawDate",
        "type": "string",
        "format": "date-time",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Payment",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "payment",
        "key": "refundRequests",
        "type": "array",
        "format": "payment-refund-request",
        "title": "refundRequests",
        "additionalProperties": [
            {
                "mapped": "payment"
            }
        ],
        "description": "",
        "className": "Payment",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "payment",
        "key": "refundSource",
        "type": "rel",
        "format": "payment",
        "title": "Refund source",
        "additionalProperties": [],
        "description": "",
        "className": "Payment",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "payment",
        "key": "refundUrl",
        "type": "string",
        "format": "",
        "title": "refundUrl",
        "additionalProperties": [],
        "description": "",
        "className": "Payment",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "payment",
        "key": "refundedBy",
        "type": "rel",
        "format": "payment",
        "title": "Refunded",
        "additionalProperties": [],
        "description": "",
        "className": "Payment",
        "isDb": false,
        "dbType": "",
        "naeType": "object"
    },
    {
        "schema": "payment",
        "key": "skipValidation",
        "type": "boolean",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Payment",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "payment",
        "key": "source",
        "type": "string",
        "format": "",
        "title": "Source",
        "additionalProperties": [],
        "description": "",
        "className": "Payment",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "payment",
        "key": "sourceId",
        "type": "string",
        "format": "",
        "title": "Source ID",
        "additionalProperties": [],
        "description": "",
        "className": "Payment",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "payment",
        "key": "sourceType",
        "type": "string",
        "format": "",
        "title": "Source type",
        "additionalProperties": [],
        "description": "",
        "className": "Payment",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "payment",
        "key": "timezoneDate",
        "type": "string",
        "format": "date-time",
        "title": "Date US",
        "additionalProperties": [],
        "description": "",
        "className": "Payment",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "payment",
        "key": "timezoneReportDate",
        "type": "string",
        "format": "date-time",
        "title": "Report date, US",
        "additionalProperties": [],
        "description": "",
        "className": "Payment",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "payment",
        "key": "total",
        "type": "number",
        "format": "float",
        "title": "Total",
        "additionalProperties": [],
        "description": "",
        "className": "Payment",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "payment",
        "key": "total2",
        "type": "number",
        "format": "float",
        "title": "total",
        "additionalProperties": [],
        "description": "",
        "className": "Payment",
        "isDb": false,
        "dbType": "",
        "naeType": "float"
    },
    {
        "schema": "payment",
        "key": "type",
        "type": "string",
        "format": "",
        "title": "Type",
        "additionalProperties": [],
        "description": "",
        "className": "Payment",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "payment",
        "key": "updatedAt",
        "type": "string",
        "format": "datetime",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Payment",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "payment",
        "key": "upsell",
        "type": "boolean",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Payment",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "payment-card-info",
        "key": "cardBrand",
        "type": "string",
        "format": "",
        "title": "cardBrand",
        "additionalProperties": [],
        "description": "",
        "className": "PaymentCardInfo",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "payment-card-info",
        "key": "cardType",
        "type": "string",
        "format": "",
        "title": "cardType",
        "additionalProperties": [],
        "description": "",
        "className": "PaymentCardInfo",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "payment-card-info",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "PaymentCardInfo",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "payment-card-info",
        "key": "payment",
        "type": "rel",
        "format": "payment",
        "title": "payment",
        "additionalProperties": [],
        "description": "",
        "className": "PaymentCardInfo",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "payment-charge-info",
        "key": "alertDate",
        "type": "string",
        "format": "date-time",
        "title": "alertDate",
        "additionalProperties": [],
        "description": "",
        "className": "PaymentChargeInfo",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "payment-charge-info",
        "key": "chargeBackDate",
        "type": "string",
        "format": "date-time",
        "title": "chargeBackDate",
        "additionalProperties": [],
        "description": "",
        "className": "PaymentChargeInfo",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "payment-charge-info",
        "key": "hasAlert",
        "type": "boolean",
        "format": "",
        "title": "hasAlert",
        "additionalProperties": [],
        "description": "",
        "className": "PaymentChargeInfo",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "payment-charge-info",
        "key": "hasChargeback",
        "type": "boolean",
        "format": "",
        "title": "hasChargeback",
        "additionalProperties": [],
        "description": "",
        "className": "PaymentChargeInfo",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "payment-charge-info",
        "key": "hasRdr",
        "type": "boolean",
        "format": "",
        "title": "hasRdr",
        "additionalProperties": [],
        "description": "",
        "className": "PaymentChargeInfo",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "payment-charge-info",
        "key": "hasRefund",
        "type": "boolean",
        "format": "",
        "title": "hasRefund",
        "additionalProperties": [],
        "description": "",
        "className": "PaymentChargeInfo",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "payment-charge-info",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "PaymentChargeInfo",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "payment-charge-info",
        "key": "payment",
        "type": "rel",
        "format": "payment",
        "title": "payment",
        "additionalProperties": [],
        "description": "",
        "className": "PaymentChargeInfo",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "payment-charge-info",
        "key": "rdrDate",
        "type": "string",
        "format": "date-time",
        "title": "rdrDate",
        "additionalProperties": [],
        "description": "",
        "className": "PaymentChargeInfo",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "payment-charge-info",
        "key": "refundDate",
        "type": "string",
        "format": "date-time",
        "title": "refundDate",
        "additionalProperties": [],
        "description": "",
        "className": "PaymentChargeInfo",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "payment-orchestrator",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "PaymentOrchestrator",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "payment-orchestrator",
        "key": "orchestratorId",
        "type": "string",
        "format": "",
        "title": "orchestratorId",
        "additionalProperties": [],
        "description": "",
        "className": "PaymentOrchestrator",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "payment-orchestrator",
        "key": "orchestratorName",
        "type": "string",
        "format": "",
        "title": "orchestratorName",
        "additionalProperties": [],
        "description": "",
        "className": "PaymentOrchestrator",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "payment-orchestrator",
        "key": "payment",
        "type": "rel",
        "format": "payment",
        "title": "payment",
        "additionalProperties": [],
        "description": "",
        "className": "PaymentOrchestrator",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "payment-orchestrator",
        "key": "pspId",
        "type": "string",
        "format": "",
        "title": "pspId",
        "additionalProperties": [],
        "description": "",
        "className": "PaymentOrchestrator",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "payment-orchestrator",
        "key": "pspName",
        "type": "string",
        "format": "",
        "title": "pspName",
        "additionalProperties": [],
        "description": "",
        "className": "PaymentOrchestrator",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "payment-psp",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "PaymentPsp",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "payment-psp",
        "key": "payment",
        "type": "rel",
        "format": "payment",
        "title": "payment",
        "additionalProperties": [],
        "description": "",
        "className": "PaymentPsp",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "payment-psp",
        "key": "pspId",
        "type": "string",
        "format": "",
        "title": "pspId",
        "additionalProperties": [],
        "description": "",
        "className": "PaymentPsp",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "payment-psp",
        "key": "pspName",
        "type": "string",
        "format": "",
        "title": "pspName",
        "additionalProperties": [],
        "description": "",
        "className": "PaymentPsp",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "payment-refund-info",
        "key": "chargeBackDate",
        "type": "string",
        "format": "date-time",
        "title": "chargeBackDate",
        "additionalProperties": [],
        "description": "",
        "className": "PaymentRefundInfo",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "payment-refund-info",
        "key": "chargeDate",
        "type": "string",
        "format": "date-time",
        "title": "chargeDate",
        "additionalProperties": [],
        "description": "",
        "className": "PaymentRefundInfo",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "payment-refund-info",
        "key": "chargeId",
        "type": "string",
        "format": "",
        "title": "chargeId",
        "additionalProperties": [],
        "description": "",
        "className": "PaymentRefundInfo",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "payment-refund-info",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "PaymentRefundInfo",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "payment-refund-info",
        "key": "payment",
        "type": "rel",
        "format": "payment",
        "title": "payment",
        "additionalProperties": [],
        "description": "",
        "className": "PaymentRefundInfo",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "payment-refund-info",
        "key": "type",
        "type": "string",
        "format": "",
        "title": "type",
        "additionalProperties": [],
        "description": "",
        "className": "PaymentRefundInfo",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "payment-refund-request",
        "key": "cancelActiveSubscription",
        "type": "boolean",
        "format": "",
        "title": "Cancel subscription",
        "additionalProperties": [],
        "description": "",
        "className": "PaymentRefundRequest",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "payment-refund-request",
        "key": "createdAt",
        "type": "string",
        "format": "datetime",
        "title": "Created At",
        "additionalProperties": [],
        "description": "",
        "className": "PaymentRefundRequest",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "payment-refund-request",
        "key": "creator",
        "type": "rel",
        "format": "user",
        "title": "Creator",
        "additionalProperties": [],
        "description": "",
        "className": "PaymentRefundRequest",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "payment-refund-request",
        "key": "customer",
        "type": "rel",
        "format": "customer",
        "title": "Customer",
        "additionalProperties": [],
        "description": "",
        "className": "PaymentRefundRequest",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "payment-refund-request",
        "key": "doer",
        "type": "rel",
        "format": "user",
        "title": "Responsible",
        "additionalProperties": [],
        "description": "",
        "className": "PaymentRefundRequest",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "payment-refund-request",
        "key": "hasRefund",
        "type": "boolean",
        "format": "",
        "title": "Has refund",
        "additionalProperties": [],
        "description": "",
        "className": "PaymentRefundRequest",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "payment-refund-request",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "PaymentRefundRequest",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "payment-refund-request",
        "key": "mainOrderId",
        "type": "string",
        "format": "",
        "title": "mainOrderId",
        "additionalProperties": [],
        "description": "",
        "className": "PaymentRefundRequest",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "payment-refund-request",
        "key": "noRefundComment",
        "type": "string",
        "format": "text",
        "title": "Comment",
        "additionalProperties": [],
        "description": "",
        "className": "PaymentRefundRequest",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "payment-refund-request",
        "key": "payment",
        "type": "rel",
        "format": "payment",
        "title": "Payment",
        "additionalProperties": [],
        "description": "",
        "className": "PaymentRefundRequest",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "payment-refund-request",
        "key": "reason",
        "type": "string",
        "format": "text",
        "title": "Reason",
        "additionalProperties": [],
        "description": "",
        "className": "PaymentRefundRequest",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "payment-refund-request",
        "key": "scopes",
        "type": "array",
        "format": "string",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "PaymentRefundRequest",
        "isDb": false,
        "dbType": "",
        "naeType": "string_array"
    },
    {
        "schema": "payment-refund-request",
        "key": "skipValidation",
        "type": "boolean",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "PaymentRefundRequest",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "payment-refund-request",
        "key": "status",
        "type": "integer",
        "format": "",
        "title": "Status",
        "additionalProperties": [],
        "description": "",
        "className": "PaymentRefundRequest",
        "isDb": true,
        "dbType": "int",
        "as": "status",
        "naeType": "status"
    },
    {
        "schema": "payment-refund-request",
        "key": "total",
        "type": "number",
        "format": "float",
        "title": "Total",
        "additionalProperties": [],
        "description": "",
        "className": "PaymentRefundRequest",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "payment-refund-request",
        "key": "updatedAt",
        "type": "string",
        "format": "datetime",
        "title": "Updated At",
        "additionalProperties": [],
        "description": "",
        "className": "PaymentRefundRequest",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "payment-tax-info",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "PaymentTaxInfo",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "payment-tax-info",
        "key": "payment",
        "type": "rel",
        "format": "payment",
        "title": "payment",
        "additionalProperties": [],
        "description": "",
        "className": "PaymentTaxInfo",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "payment-tax-info",
        "key": "percent",
        "type": "number",
        "format": "float",
        "title": "Tax percent",
        "additionalProperties": [],
        "description": "",
        "className": "PaymentTaxInfo",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "payment-tax-info",
        "key": "total",
        "type": "number",
        "format": "float",
        "title": "Tax total",
        "additionalProperties": [],
        "description": "",
        "className": "PaymentTaxInfo",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "payment-tax-info",
        "key": "totalWoTax",
        "type": "number",
        "format": "float",
        "title": "Total wo tax",
        "additionalProperties": [],
        "description": "",
        "className": "PaymentTaxInfo",
        "isDb": false,
        "dbType": "",
        "naeType": "float"
    },
    {
        "schema": "quiz-result",
        "key": "_ViewTitle",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "QuizResult",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "quiz-result",
        "key": "country",
        "type": "string",
        "format": "",
        "title": "country",
        "additionalProperties": [],
        "description": "",
        "className": "QuizResult",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "quiz-result",
        "key": "customer",
        "type": "rel",
        "format": "customer",
        "title": "Customer",
        "additionalProperties": [],
        "description": "",
        "className": "QuizResult",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "quiz-result",
        "key": "date",
        "type": "string",
        "format": "date-time",
        "title": "Date",
        "additionalProperties": [],
        "description": "",
        "className": "QuizResult",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "quiz-result",
        "key": "dateUsFormatted",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "QuizResult",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "quiz-result",
        "key": "email",
        "type": "string",
        "format": "",
        "title": "Email",
        "additionalProperties": [],
        "description": "",
        "className": "QuizResult",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "quiz-result",
        "key": "externalId",
        "type": "string",
        "format": "",
        "title": "External ID",
        "additionalProperties": [],
        "description": "",
        "className": "QuizResult",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "quiz-result",
        "key": "frontend",
        "type": "rel",
        "format": "frontend",
        "title": "Frontend",
        "additionalProperties": [],
        "description": "",
        "className": "QuizResult",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "quiz-result",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "QuizResult",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "quiz-result",
        "key": "lastStep",
        "type": "string",
        "format": "",
        "title": "Last step",
        "additionalProperties": [],
        "description": "",
        "className": "QuizResult",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "quiz-result",
        "key": "payResult",
        "type": "string",
        "format": "",
        "title": "payResult",
        "additionalProperties": [],
        "description": "",
        "className": "QuizResult",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "quiz-result",
        "key": "paymentMethod",
        "type": "string",
        "format": "",
        "title": "Payment method",
        "additionalProperties": [],
        "description": "",
        "className": "QuizResult",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "quiz-result",
        "key": "plan",
        "type": "string",
        "format": "",
        "title": "Plan",
        "additionalProperties": [],
        "description": "",
        "className": "QuizResult",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "quiz-result",
        "key": "planPrice",
        "type": "number",
        "format": "float",
        "title": "Price",
        "additionalProperties": [],
        "description": "",
        "className": "QuizResult",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "quiz-result",
        "key": "query",
        "type": "array",
        "format": "string",
        "title": "query",
        "additionalProperties": [],
        "description": "",
        "className": "QuizResult",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "quiz-result",
        "key": "questionsCompleted",
        "type": "integer",
        "format": "",
        "title": "Questions Completed",
        "additionalProperties": [],
        "description": "",
        "className": "QuizResult",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "quiz-result",
        "key": "referenceId",
        "type": "string",
        "format": "",
        "title": "Reference ID",
        "additionalProperties": [],
        "description": "",
        "className": "QuizResult",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "quiz-result",
        "key": "results",
        "type": "array",
        "format": "string",
        "title": "Results",
        "additionalProperties": [],
        "description": "",
        "className": "QuizResult",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "quiz-result",
        "key": "scopes",
        "type": "array",
        "format": "string",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "QuizResult",
        "isDb": false,
        "dbType": "",
        "naeType": "string_array"
    },
    {
        "schema": "quiz-result",
        "key": "slug",
        "type": "string",
        "format": "",
        "title": "Slug",
        "additionalProperties": [],
        "description": "",
        "className": "QuizResult",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "quiz-result",
        "key": "subscription",
        "type": "rel",
        "format": "subscription",
        "title": "subscription",
        "additionalProperties": [],
        "description": "",
        "className": "QuizResult",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "quiz-result",
        "key": "timezoneDate",
        "type": "string",
        "format": "date-time",
        "title": "Date US",
        "additionalProperties": [],
        "description": "",
        "className": "QuizResult",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "quiz-result",
        "key": "timezoneReportDate",
        "type": "string",
        "format": "date-time",
        "title": "timezoneReportDate",
        "additionalProperties": [],
        "description": "",
        "className": "QuizResult",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "quiz-result",
        "key": "upgraded",
        "type": "boolean",
        "format": "",
        "title": "Upgraded",
        "additionalProperties": [],
        "description": "",
        "className": "QuizResult",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "report-item",
        "key": "_viewTitle",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "ReportItem",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "report-item",
        "key": "adSource",
        "type": "string",
        "format": "",
        "title": "Ad source",
        "additionalProperties": [],
        "description": "",
        "className": "ReportItem",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "report-item",
        "key": "adSourceAdopted",
        "type": "string",
        "format": "",
        "title": "adSource",
        "additionalProperties": [],
        "description": "",
        "className": "ReportItem",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "report-item",
        "key": "adSourceL2",
        "type": "string",
        "format": "",
        "title": "adSource",
        "additionalProperties": [],
        "description": "",
        "className": "ReportItem",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "report-item",
        "key": "boughtCouch",
        "type": "boolean",
        "format": "",
        "title": "boughtCouch",
        "additionalProperties": [],
        "description": "",
        "className": "ReportItem",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "report-item",
        "key": "cancelationReason",
        "type": "string",
        "format": "",
        "title": "cancelationReason",
        "additionalProperties": [],
        "description": "",
        "className": "ReportItem",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "string"
    },
    {
        "schema": "report-item",
        "key": "chargebacked",
        "type": "boolean",
        "format": "",
        "title": "chargebacked",
        "additionalProperties": [],
        "description": "",
        "className": "ReportItem",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "report-item",
        "key": "country",
        "type": "string",
        "format": "",
        "title": "Country",
        "additionalProperties": [],
        "description": "",
        "className": "ReportItem",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "report-item",
        "key": "customerEmail",
        "type": "string",
        "format": "",
        "title": "Customer",
        "additionalProperties": [],
        "description": "",
        "className": "ReportItem",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "report-item",
        "key": "customerEmailAlternate",
        "type": "string",
        "format": "",
        "title": "customerEmailAlternate",
        "additionalProperties": [],
        "description": "",
        "className": "ReportItem",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "report-item",
        "key": "gender",
        "type": "string",
        "format": "",
        "title": "Gender",
        "additionalProperties": [],
        "description": "",
        "className": "ReportItem",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "report-item",
        "key": "grossSubscriptions",
        "type": "array",
        "format": "string",
        "title": "grossSubscriptions",
        "additionalProperties": [],
        "description": "",
        "className": "ReportItem",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "report-item",
        "key": "grossUpsells",
        "type": "array",
        "format": "string",
        "title": "grossUpsells",
        "additionalProperties": [],
        "description": "",
        "className": "ReportItem",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "report-item",
        "key": "grossValues",
        "type": "array",
        "format": "string",
        "title": "grossValues",
        "additionalProperties": [],
        "description": "",
        "className": "ReportItem",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "report-item",
        "key": "hasCancelations",
        "type": "boolean",
        "format": "",
        "title": "hasCancelations",
        "additionalProperties": [],
        "description": "",
        "className": "ReportItem",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "report-item",
        "key": "hasCancelationsLifetime",
        "type": "boolean",
        "format": "",
        "title": "hasCancelationsLifetime",
        "additionalProperties": [],
        "description": "",
        "className": "ReportItem",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "report-item",
        "key": "hasCancelationsSystem",
        "type": "boolean",
        "format": "",
        "title": "hasCancelationsSystem",
        "additionalProperties": [],
        "description": "",
        "className": "ReportItem",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "report-item",
        "key": "hasRefundRequests",
        "type": "boolean",
        "format": "",
        "title": "hasRefundRequests",
        "additionalProperties": [],
        "description": "",
        "className": "ReportItem",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "report-item",
        "key": "hasTrial",
        "type": "boolean",
        "format": "",
        "title": "hasTrial",
        "additionalProperties": [],
        "description": "",
        "className": "ReportItem",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "report-item",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "ReportItem",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "report-item",
        "key": "initialTotal",
        "type": "number",
        "format": "float",
        "title": "initialTotal",
        "additionalProperties": [],
        "description": "",
        "className": "ReportItem",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "report-item",
        "key": "invoiceLink",
        "type": "string",
        "format": "",
        "title": "Invoice link",
        "additionalProperties": [],
        "description": "",
        "className": "ReportItem",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "report-item",
        "key": "language",
        "type": "string",
        "format": "",
        "title": "Language",
        "additionalProperties": [],
        "description": "",
        "className": "ReportItem",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "report-item",
        "key": "lifetime",
        "type": "boolean",
        "format": "",
        "title": "Lifetime",
        "additionalProperties": [],
        "description": "",
        "className": "ReportItem",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "report-item",
        "key": "needSync",
        "type": "boolean",
        "format": "",
        "title": "needSync",
        "additionalProperties": [],
        "description": "",
        "className": "ReportItem",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "report-item",
        "key": "netValues",
        "type": "array",
        "format": "string",
        "title": "netValues",
        "additionalProperties": [],
        "description": "",
        "className": "ReportItem",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "report-item",
        "key": "onetime",
        "type": "boolean",
        "format": "",
        "title": "onetime",
        "additionalProperties": [],
        "description": "",
        "className": "ReportItem",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "report-item",
        "key": "orderId",
        "type": "string",
        "format": "",
        "title": "Order ID",
        "additionalProperties": [],
        "description": "",
        "className": "ReportItem",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "report-item",
        "key": "orderItems",
        "type": "array",
        "format": "string",
        "title": "orderItems",
        "additionalProperties": [],
        "description": "",
        "className": "ReportItem",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "report-item",
        "key": "origin",
        "type": "string",
        "format": "",
        "title": "origin",
        "additionalProperties": [],
        "description": "",
        "className": "ReportItem",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "report-item",
        "key": "paymentSource",
        "type": "string",
        "format": "",
        "title": "Payment source",
        "additionalProperties": [],
        "description": "",
        "className": "ReportItem",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "string"
    },
    {
        "schema": "report-item",
        "key": "period",
        "type": "string",
        "format": "",
        "title": "Period",
        "additionalProperties": [],
        "description": "",
        "className": "ReportItem",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "report-item",
        "key": "periodWithTotal",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "ReportItem",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "report-item",
        "key": "planPrice",
        "type": "number",
        "format": "float",
        "title": "Plan price",
        "additionalProperties": [],
        "description": "",
        "className": "ReportItem",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "report-item",
        "key": "planSku",
        "type": "string",
        "format": "",
        "title": "planSku",
        "additionalProperties": [],
        "description": "",
        "className": "ReportItem",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "report-item",
        "key": "quizTimezoneDate",
        "type": "string",
        "format": "date-time",
        "title": "quizTimezoneDate",
        "additionalProperties": [],
        "description": "",
        "className": "ReportItem",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "report-item",
        "key": "recurringAll",
        "type": "integer",
        "format": "",
        "title": "recurringAll",
        "additionalProperties": [],
        "description": "",
        "className": "ReportItem",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "report-item",
        "key": "recurringByDates",
        "type": "array",
        "format": "string",
        "title": "recurringByDates",
        "additionalProperties": [],
        "description": "",
        "className": "ReportItem",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "report-item",
        "key": "recurringError",
        "type": "integer",
        "format": "",
        "title": "recurringError",
        "additionalProperties": [],
        "description": "",
        "className": "ReportItem",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "report-item",
        "key": "recurringSuccess",
        "type": "integer",
        "format": "",
        "title": "recurringSuccess",
        "additionalProperties": [],
        "description": "",
        "className": "ReportItem",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "report-item",
        "key": "recurringTotal",
        "type": "number",
        "format": "float",
        "title": "recurringTotal",
        "additionalProperties": [],
        "description": "",
        "className": "ReportItem",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "report-item",
        "key": "recurringWaiting",
        "type": "integer",
        "format": "",
        "title": "recurringWaiting",
        "additionalProperties": [],
        "description": "",
        "className": "ReportItem",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "report-item",
        "key": "refundSubscriptions",
        "type": "array",
        "format": "string",
        "title": "refundSubscriptions",
        "additionalProperties": [],
        "description": "",
        "className": "ReportItem",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "report-item",
        "key": "refundTotal",
        "type": "number",
        "format": "float",
        "title": "refundTotal",
        "additionalProperties": [],
        "description": "",
        "className": "ReportItem",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "report-item",
        "key": "refundUpsells",
        "type": "array",
        "format": "string",
        "title": "refundUpsells",
        "additionalProperties": [],
        "description": "",
        "className": "ReportItem",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "report-item",
        "key": "refunded",
        "type": "boolean",
        "format": "",
        "title": "refunded",
        "additionalProperties": [],
        "description": "",
        "className": "ReportItem",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "report-item",
        "key": "reportItemAdSource",
        "type": "array",
        "format": "",
        "title": "reportItemAdSource",
        "additionalProperties": [],
        "description": "",
        "className": "ReportItem",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "report-item",
        "key": "reportItemFunnel",
        "type": "array",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "ReportItem",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "report-item",
        "key": "reportItemPayment",
        "type": "array",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "ReportItem",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "report-item",
        "key": "reportItemReturn",
        "type": "array",
        "format": "",
        "title": "reportItemReturn",
        "additionalProperties": [],
        "description": "",
        "className": "ReportItem",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "report-item",
        "key": "reportItemReturnEl",
        "type": "rel",
        "format": "report-item-return",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "ReportItem",
        "isDb": false,
        "dbType": "",
        "naeType": "object"
    },
    {
        "schema": "report-item",
        "key": "scopes",
        "type": "array",
        "format": "string",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "ReportItem",
        "isDb": false,
        "dbType": "",
        "naeType": "string_array"
    },
    {
        "schema": "report-item",
        "key": "showNewVersion",
        "type": "boolean",
        "format": "",
        "title": "showNewVersion",
        "additionalProperties": [],
        "description": "",
        "className": "ReportItem",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "report-item",
        "key": "slug",
        "type": "string",
        "format": "",
        "title": "Funnel",
        "additionalProperties": [],
        "description": "",
        "className": "ReportItem",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "report-item",
        "key": "slugRaw",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "ReportItem",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "report-item",
        "key": "stopDate",
        "type": "string",
        "format": "date-time",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "ReportItem",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "report-item",
        "key": "stopDateFixed",
        "type": "string",
        "format": "date-time",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "ReportItem",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "report-item",
        "key": "stopOnPeriod",
        "type": "integer",
        "format": "",
        "title": "stopOnPeriod",
        "additionalProperties": [],
        "description": "",
        "className": "ReportItem",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "report-item",
        "key": "stopReason",
        "type": "string",
        "format": "",
        "title": "Stop reason",
        "additionalProperties": [],
        "description": "",
        "className": "ReportItem",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "string"
    },
    {
        "schema": "report-item",
        "key": "subFinishDate",
        "type": "string",
        "format": "date-time",
        "title": "subFinishDate",
        "additionalProperties": [],
        "description": "",
        "className": "ReportItem",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "report-item",
        "key": "subOnPeriod",
        "type": "integer",
        "format": "",
        "title": "subOnPeriod",
        "additionalProperties": [],
        "description": "",
        "className": "ReportItem",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "report-item",
        "key": "subStartDate",
        "type": "string",
        "format": "date-time",
        "title": "subStartDate",
        "additionalProperties": [],
        "description": "",
        "className": "ReportItem",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "report-item",
        "key": "subscriptionSource",
        "type": "string",
        "format": "",
        "title": "Subscription source",
        "additionalProperties": [],
        "description": "",
        "className": "ReportItem",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "string"
    },
    {
        "schema": "report-item",
        "key": "subscriptionStatus",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "ReportItem",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "report-item",
        "key": "testUser",
        "type": "boolean",
        "format": "",
        "title": "Test user",
        "additionalProperties": [],
        "description": "",
        "className": "ReportItem",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "report-item",
        "key": "timezoneCancelDate",
        "type": "string",
        "format": "date-time",
        "title": "timezoneCancelDate",
        "additionalProperties": [],
        "description": "",
        "className": "ReportItem",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "report-item",
        "key": "timezoneDate",
        "type": "string",
        "format": "date",
        "title": "Subscription start",
        "additionalProperties": [],
        "description": "",
        "className": "ReportItem",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "date"
    },
    {
        "schema": "report-item",
        "key": "timezoneFinishDate",
        "type": "string",
        "format": "date-time",
        "title": "timezoneFinishDate",
        "additionalProperties": [],
        "description": "",
        "className": "ReportItem",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "report-item",
        "key": "timezoneRecurringErrorDate",
        "type": "string",
        "format": "date-time",
        "title": "timezoneRecurringErrorDate",
        "additionalProperties": [],
        "description": "",
        "className": "ReportItem",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "report-item",
        "key": "timezoneReportDate",
        "type": "string",
        "format": "date-time",
        "title": "timezoneReportDate",
        "additionalProperties": [],
        "description": "",
        "className": "ReportItem",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "report-item",
        "key": "timezoneStopDate",
        "type": "string",
        "format": "date",
        "title": "Stop date",
        "additionalProperties": [],
        "description": "",
        "className": "ReportItem",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "date"
    },
    {
        "schema": "report-item",
        "key": "updatedAt",
        "type": "string",
        "format": "date-time",
        "title": "updatedAt",
        "additionalProperties": [],
        "description": "",
        "className": "ReportItem",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "report-item",
        "key": "upsellLink",
        "type": "string",
        "format": "",
        "title": "Upsell link",
        "additionalProperties": [],
        "description": "",
        "className": "ReportItem",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "report-item",
        "key": "upsellSkus",
        "type": "array",
        "format": "string",
        "title": "upsellSkus",
        "additionalProperties": [],
        "description": "",
        "className": "ReportItem",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "report-item",
        "key": "upsellTotal",
        "type": "number",
        "format": "float",
        "title": "upsellTotal",
        "additionalProperties": [],
        "description": "",
        "className": "ReportItem",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "report-item",
        "key": "validForReport",
        "type": "boolean",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "ReportItem",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "report-item-ad-source",
        "key": "adSourceLevel1",
        "type": "string",
        "format": "",
        "title": "adSourceLevel1",
        "additionalProperties": [],
        "description": "",
        "className": "ReportItemAdSource",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "report-item-ad-source",
        "key": "adSourceLevel1Adopted",
        "type": "string",
        "format": "",
        "title": "adSourceLevel1Adopted",
        "additionalProperties": [],
        "description": "",
        "className": "ReportItemAdSource",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "report-item-ad-source",
        "key": "adSourceLevel2",
        "type": "string",
        "format": "",
        "title": "adSourceLevel2",
        "additionalProperties": [],
        "description": "",
        "className": "ReportItemAdSource",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "report-item-ad-source",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "ReportItemAdSource",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "report-item-ad-source",
        "key": "reportItem",
        "type": "rel",
        "format": "report-item",
        "title": "reportItem",
        "additionalProperties": [],
        "description": "",
        "className": "ReportItemAdSource",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "report-item-funnel",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "ReportItemFunnel",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "report-item-funnel",
        "key": "initialFunnel",
        "type": "string",
        "format": "",
        "title": "initialFunnel",
        "additionalProperties": [],
        "description": "",
        "className": "ReportItemFunnel",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "report-item-funnel",
        "key": "purchasedFunnel",
        "type": "string",
        "format": "",
        "title": "purchasedFunnel",
        "additionalProperties": [],
        "description": "",
        "className": "ReportItemFunnel",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "report-item-funnel",
        "key": "reportItem",
        "type": "rel",
        "format": "report-item",
        "title": "reportItem",
        "additionalProperties": [],
        "description": "",
        "className": "ReportItemFunnel",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "report-item-payment",
        "key": "firstPaymentDate",
        "type": "string",
        "format": "date-time",
        "title": "First Payment Date",
        "additionalProperties": [],
        "description": "",
        "className": "ReportItemPayment",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "report-item-payment",
        "key": "grossInitialLifetimeTotal",
        "type": "number",
        "format": "float",
        "title": "Get the value of grossInitialLifetimeTotal",
        "additionalProperties": [],
        "description": "",
        "className": "ReportItemPayment",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "report-item-payment",
        "key": "grossInitialSubTotal",
        "type": "number",
        "format": "float",
        "title": "Gross Initial Sub Total",
        "additionalProperties": [],
        "description": "",
        "className": "ReportItemPayment",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "report-item-payment",
        "key": "grossRecurringTotal",
        "type": "number",
        "format": "float",
        "title": "Gross Recurring Total",
        "additionalProperties": [],
        "description": "",
        "className": "ReportItemPayment",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "report-item-payment",
        "key": "grossTotal",
        "type": "number",
        "format": "float",
        "title": "Gross Total",
        "additionalProperties": [],
        "description": "",
        "className": "ReportItemPayment",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "report-item-payment",
        "key": "grossUpsellTotal",
        "type": "number",
        "format": "float",
        "title": "Gross Upsell Total",
        "additionalProperties": [],
        "description": "",
        "className": "ReportItemPayment",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "report-item-payment",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "ReportItemPayment",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "report-item-payment",
        "key": "lastPaymentDate",
        "type": "string",
        "format": "date-time",
        "title": "Last Payment Date",
        "additionalProperties": [],
        "description": "",
        "className": "ReportItemPayment",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "report-item-payment",
        "key": "netInitialLifetimeTotal",
        "type": "number",
        "format": "float",
        "title": "Get the value of netInitialLifetimeTotal",
        "additionalProperties": [],
        "description": "",
        "className": "ReportItemPayment",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "report-item-payment",
        "key": "netInitialSubTotal",
        "type": "number",
        "format": "float",
        "title": "Net Initial Sub Total",
        "additionalProperties": [],
        "description": "",
        "className": "ReportItemPayment",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "report-item-payment",
        "key": "netRecurringTotal",
        "type": "number",
        "format": "float",
        "title": "Net Recurring Total",
        "additionalProperties": [],
        "description": "",
        "className": "ReportItemPayment",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "report-item-payment",
        "key": "netTotal",
        "type": "number",
        "format": "float",
        "title": "Net Total",
        "additionalProperties": [],
        "description": "",
        "className": "ReportItemPayment",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "report-item-payment",
        "key": "netUpsellTotal",
        "type": "number",
        "format": "float",
        "title": "Net Upsell Total",
        "additionalProperties": [],
        "description": "",
        "className": "ReportItemPayment",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "report-item-payment",
        "key": "orderId",
        "type": "string",
        "format": "",
        "title": "Order ID",
        "additionalProperties": [],
        "description": "",
        "className": "ReportItemPayment",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "report-item-payment",
        "key": "paymentsCount",
        "type": "integer",
        "format": "",
        "title": "Payments Count",
        "additionalProperties": [],
        "description": "",
        "className": "ReportItemPayment",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "report-item-payment",
        "key": "paymentsInCount",
        "type": "integer",
        "format": "",
        "title": "Payments In Count",
        "additionalProperties": [],
        "description": "",
        "className": "ReportItemPayment",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "report-item-payment",
        "key": "paymentsOutCount",
        "type": "integer",
        "format": "",
        "title": "Payments Out Count",
        "additionalProperties": [],
        "description": "",
        "className": "ReportItemPayment",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "report-item-payment",
        "key": "reportItem",
        "type": "rel",
        "format": "report-item",
        "title": "reportItem",
        "additionalProperties": [],
        "description": "",
        "className": "ReportItemPayment",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "report-item-return",
        "key": "alertCount",
        "type": "integer",
        "format": "",
        "title": "alertCount",
        "additionalProperties": [],
        "description": "",
        "className": "ReportItemReturn",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "report-item-return",
        "key": "alertTotal",
        "type": "number",
        "format": "float",
        "title": "alertTotal",
        "additionalProperties": [],
        "description": "",
        "className": "ReportItemReturn",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "report-item-return",
        "key": "chargeBackCount",
        "type": "integer",
        "format": "",
        "title": "chargeBackCount",
        "additionalProperties": [],
        "description": "",
        "className": "ReportItemReturn",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "report-item-return",
        "key": "chargeBackTotal",
        "type": "number",
        "format": "float",
        "title": "chargeBackTotal",
        "additionalProperties": [],
        "description": "",
        "className": "ReportItemReturn",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "report-item-return",
        "key": "firstAlertOnPeriod",
        "type": "integer",
        "format": "",
        "title": "firstAlertOnPeriod",
        "additionalProperties": [],
        "description": "",
        "className": "ReportItemReturn",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "report-item-return",
        "key": "firstChargeBackOnPeriod",
        "type": "integer",
        "format": "",
        "title": "firstChargeBackOnPeriod",
        "additionalProperties": [],
        "description": "",
        "className": "ReportItemReturn",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "report-item-return",
        "key": "firstRefundOnPeriod",
        "type": "integer",
        "format": "",
        "title": "firstRefundOnPeriod",
        "additionalProperties": [],
        "description": "",
        "className": "ReportItemReturn",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "report-item-return",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "ReportItemReturn",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "report-item-return",
        "key": "refundCount",
        "type": "integer",
        "format": "",
        "title": "refundCount",
        "additionalProperties": [],
        "description": "",
        "className": "ReportItemReturn",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "report-item-return",
        "key": "refundTotal",
        "type": "number",
        "format": "float",
        "title": "refundTotal",
        "additionalProperties": [],
        "description": "",
        "className": "ReportItemReturn",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "report-item-return",
        "key": "reportItem",
        "type": "rel",
        "format": "report-item",
        "title": "reportItem",
        "additionalProperties": [],
        "description": "",
        "className": "ReportItemReturn",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "report-upsell-item",
        "key": "grossValues",
        "type": "array",
        "format": "string",
        "title": "grossValues",
        "additionalProperties": [],
        "description": "",
        "className": "ReportUpsellItem",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    },
    {
        "schema": "report-upsell-item",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "ReportUpsellItem",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "report-upsell-item",
        "key": "timezoneDate",
        "type": "string",
        "format": "date-time",
        "title": "timezoneDate",
        "additionalProperties": [],
        "description": "",
        "className": "ReportUpsellItem",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "sf-explore-data-folder",
        "key": "sort",
        "type": "number",
        "format": "",
        "title": "Sort",
        "additionalProperties": [],
        "description": "",
        "className": "SfExploreDataFolder",
        "isDb": true,
        "dbType": "int",
        "naeType": "string"
    },
    {
        "schema": "sf-explore-data-folder",
        "key": "title",
        "type": "string",
        "format": "",
        "title": "Title",
        "additionalProperties": [],
        "description": "",
        "className": "SfExploreDataFolder",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sf-explore-data-item",
        "key": "columns",
        "type": "string",
        "format": "text",
        "title": "columns",
        "additionalProperties": [],
        "description": "",
        "className": "SfExploreDataItem",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "text"
    },
    {
        "schema": "sf-explore-data-item",
        "key": "exploreId",
        "type": "string",
        "format": "",
        "title": "exploreId",
        "additionalProperties": [],
        "description": "",
        "className": "SfExploreDataItem",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sf-explore-data-item",
        "key": "folder",
        "type": "rel",
        "format": "sf-explore-data-folder",
        "title": "Folder",
        "additionalProperties": [],
        "description": "",
        "className": "SfExploreDataItem",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "sf-explore-data-item",
        "key": "sort",
        "type": "number",
        "format": "",
        "title": "Sort",
        "additionalProperties": [],
        "description": "",
        "className": "SfExploreDataItem",
        "isDb": true,
        "dbType": "int",
        "naeType": "string"
    },
    {
        "schema": "sf-explore-data-item",
        "key": "sqlCount",
        "type": "string",
        "format": "text",
        "title": "sqlCount",
        "additionalProperties": [],
        "description": "",
        "className": "SfExploreDataItem",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "text"
    },
    {
        "schema": "sf-explore-data-item",
        "key": "sqlData",
        "type": "string",
        "format": "text",
        "title": "sqlData",
        "additionalProperties": [],
        "description": "",
        "className": "SfExploreDataItem",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "text"
    },
    {
        "schema": "sf-explore-data-item",
        "key": "title",
        "type": "string",
        "format": "",
        "title": "title",
        "additionalProperties": [],
        "description": "",
        "className": "SfExploreDataItem",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sf-key-value-orm",
        "key": "sfKey",
        "type": "string",
        "format": "",
        "title": "sfKey",
        "additionalProperties": [],
        "description": "",
        "className": "SfKeyValueOrm",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sf-key-value-orm",
        "key": "sfKey",
        "type": "string",
        "format": "",
        "title": "sfKey",
        "additionalProperties": [],
        "description": "",
        "className": "SfKeyValueOrm",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sf-key-value-orm",
        "key": "sfKey",
        "type": "string",
        "format": "",
        "title": "sfKey",
        "additionalProperties": [],
        "description": "",
        "className": "SfKeyValueOrm",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sf-key-value-orm",
        "key": "sfKey",
        "type": "string",
        "format": "",
        "title": "sfKey",
        "additionalProperties": [],
        "description": "",
        "className": "SfKeyValueOrm",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sf-key-value-orm",
        "key": "sfKey",
        "type": "string",
        "format": "",
        "title": "sfKey",
        "additionalProperties": [],
        "description": "",
        "className": "SfKeyValueOrm",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sf-key-value-orm",
        "key": "sfKey",
        "type": "string",
        "format": "",
        "title": "sfKey",
        "additionalProperties": [],
        "description": "",
        "className": "SfKeyValueOrm",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sf-key-value-orm",
        "key": "sfKey",
        "type": "string",
        "format": "",
        "title": "sfKey",
        "additionalProperties": [],
        "description": "",
        "className": "SfKeyValueOrm",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sf-key-value-orm",
        "key": "sfKey",
        "type": "string",
        "format": "",
        "title": "sfKey",
        "additionalProperties": [],
        "description": "",
        "className": "SfKeyValueOrm",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sf-key-value-orm",
        "key": "sfKey",
        "type": "string",
        "format": "",
        "title": "sfKey",
        "additionalProperties": [],
        "description": "",
        "className": "SfKeyValueOrm",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sf-key-value-orm",
        "key": "sfKey",
        "type": "string",
        "format": "",
        "title": "sfKey",
        "additionalProperties": [],
        "description": "",
        "className": "SfKeyValueOrm",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sf-key-value-orm",
        "key": "sfKey",
        "type": "string",
        "format": "",
        "title": "sfKey",
        "additionalProperties": [],
        "description": "",
        "className": "SfKeyValueOrm",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sf-key-value-orm",
        "key": "sfKey",
        "type": "string",
        "format": "",
        "title": "sfKey",
        "additionalProperties": [],
        "description": "",
        "className": "SfKeyValueOrm",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sf-key-value-orm",
        "key": "sfKey",
        "type": "string",
        "format": "",
        "title": "sfKey",
        "additionalProperties": [],
        "description": "",
        "className": "SfKeyValueOrm",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sf-key-value-orm",
        "key": "sfKey",
        "type": "string",
        "format": "",
        "title": "sfKey",
        "additionalProperties": [],
        "description": "",
        "className": "SfKeyValueOrm",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sf-key-value-orm",
        "key": "sfKey",
        "type": "string",
        "format": "",
        "title": "sfKey",
        "additionalProperties": [],
        "description": "",
        "className": "SfKeyValueOrm",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sf-key-value-orm",
        "key": "sfKey",
        "type": "string",
        "format": "",
        "title": "sfKey",
        "additionalProperties": [],
        "description": "",
        "className": "SfKeyValueOrm",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sf-key-value-orm",
        "key": "sfKey",
        "type": "string",
        "format": "",
        "title": "sfKey",
        "additionalProperties": [],
        "description": "",
        "className": "SfKeyValueOrm",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sf-key-value-orm",
        "key": "sfKey",
        "type": "string",
        "format": "",
        "title": "sfKey",
        "additionalProperties": [],
        "description": "",
        "className": "SfKeyValueOrm",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sf-key-value-orm",
        "key": "sfValue",
        "type": "string",
        "format": "",
        "title": "sfValue",
        "additionalProperties": [],
        "description": "",
        "className": "SfKeyValueOrm",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sf-key-value-orm",
        "key": "sfValue",
        "type": "string",
        "format": "",
        "title": "sfValue",
        "additionalProperties": [],
        "description": "",
        "className": "SfKeyValueOrm",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sf-key-value-orm",
        "key": "sfValue",
        "type": "string",
        "format": "",
        "title": "sfValue",
        "additionalProperties": [],
        "description": "",
        "className": "SfKeyValueOrm",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sf-key-value-orm",
        "key": "sfValue",
        "type": "string",
        "format": "",
        "title": "sfValue",
        "additionalProperties": [],
        "description": "",
        "className": "SfKeyValueOrm",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sf-key-value-orm",
        "key": "sfValue",
        "type": "string",
        "format": "",
        "title": "sfValue",
        "additionalProperties": [],
        "description": "",
        "className": "SfKeyValueOrm",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sf-key-value-orm",
        "key": "sfValue",
        "type": "string",
        "format": "",
        "title": "sfValue",
        "additionalProperties": [],
        "description": "",
        "className": "SfKeyValueOrm",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sf-key-value-orm",
        "key": "sfValue",
        "type": "string",
        "format": "",
        "title": "sfValue",
        "additionalProperties": [],
        "description": "",
        "className": "SfKeyValueOrm",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sf-key-value-orm",
        "key": "sfValue",
        "type": "string",
        "format": "",
        "title": "sfValue",
        "additionalProperties": [],
        "description": "",
        "className": "SfKeyValueOrm",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sf-key-value-orm",
        "key": "sfValue",
        "type": "string",
        "format": "",
        "title": "sfValue",
        "additionalProperties": [],
        "description": "",
        "className": "SfKeyValueOrm",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sf-key-value-orm",
        "key": "sfValue",
        "type": "string",
        "format": "",
        "title": "sfValue",
        "additionalProperties": [],
        "description": "",
        "className": "SfKeyValueOrm",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sf-key-value-orm",
        "key": "sfValue",
        "type": "string",
        "format": "",
        "title": "sfValue",
        "additionalProperties": [],
        "description": "",
        "className": "SfKeyValueOrm",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sf-key-value-orm",
        "key": "sfValue",
        "type": "string",
        "format": "",
        "title": "sfValue",
        "additionalProperties": [],
        "description": "",
        "className": "SfKeyValueOrm",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sf-key-value-orm",
        "key": "sfValue",
        "type": "string",
        "format": "",
        "title": "sfValue",
        "additionalProperties": [],
        "description": "",
        "className": "SfKeyValueOrm",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sf-key-value-orm",
        "key": "sfValue",
        "type": "string",
        "format": "",
        "title": "sfValue",
        "additionalProperties": [],
        "description": "",
        "className": "SfKeyValueOrm",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sf-key-value-orm",
        "key": "sfValue",
        "type": "string",
        "format": "",
        "title": "sfValue",
        "additionalProperties": [],
        "description": "",
        "className": "SfKeyValueOrm",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sf-key-value-orm",
        "key": "sfValue",
        "type": "string",
        "format": "",
        "title": "sfValue",
        "additionalProperties": [],
        "description": "",
        "className": "SfKeyValueOrm",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sf-key-value-orm",
        "key": "sfValue",
        "type": "string",
        "format": "",
        "title": "sfValue",
        "additionalProperties": [],
        "description": "",
        "className": "SfKeyValueOrm",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "sf-key-value-orm",
        "key": "sfValue",
        "type": "string",
        "format": "",
        "title": "sfValue",
        "additionalProperties": [],
        "description": "",
        "className": "SfKeyValueOrm",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "shipping-order",
        "key": "addressCity",
        "type": "string",
        "format": "",
        "title": "addressCity",
        "additionalProperties": [],
        "description": "",
        "className": "ShippingOrder",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "shipping-order",
        "key": "addressCountry",
        "type": "string",
        "format": "",
        "title": "addressCountry",
        "additionalProperties": [],
        "description": "",
        "className": "ShippingOrder",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "shipping-order",
        "key": "addressLine",
        "type": "string",
        "format": "",
        "title": "addressLine",
        "additionalProperties": [],
        "description": "",
        "className": "ShippingOrder",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "shipping-order",
        "key": "addressPostal",
        "type": "string",
        "format": "",
        "title": "addressPostal",
        "additionalProperties": [],
        "description": "",
        "className": "ShippingOrder",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "shipping-order",
        "key": "addressState",
        "type": "string",
        "format": "",
        "title": "addressState",
        "additionalProperties": [],
        "description": "",
        "className": "ShippingOrder",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "shipping-order",
        "key": "clientEmail",
        "type": "string",
        "format": "",
        "title": "clientEmail",
        "additionalProperties": [],
        "description": "",
        "className": "ShippingOrder",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "shipping-order",
        "key": "clientName",
        "type": "string",
        "format": "",
        "title": "clientName",
        "additionalProperties": [],
        "description": "",
        "className": "ShippingOrder",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "shipping-order",
        "key": "clientPhone",
        "type": "string",
        "format": "",
        "title": "clientPhone",
        "additionalProperties": [],
        "description": "",
        "className": "ShippingOrder",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "shipping-order",
        "key": "date",
        "type": "string",
        "format": "date",
        "title": "date",
        "additionalProperties": [],
        "description": "",
        "className": "ShippingOrder",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "date"
    },
    {
        "schema": "shipping-order",
        "key": "deliverySla",
        "type": "string",
        "format": "",
        "title": "deliverySla",
        "additionalProperties": [],
        "description": "",
        "className": "ShippingOrder",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "shipping-order",
        "key": "fulfillmentOrderId",
        "type": "string",
        "format": "",
        "title": "fulfillmentOrderId",
        "additionalProperties": [],
        "description": "",
        "className": "ShippingOrder",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "shipping-order",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "ShippingOrder",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "shipping-order",
        "key": "orderComment",
        "type": "string",
        "format": "",
        "title": "orderComment",
        "additionalProperties": [],
        "description": "",
        "className": "ShippingOrder",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "string"
    },
    {
        "schema": "shipping-order",
        "key": "payment",
        "type": "rel",
        "format": "payment",
        "title": "payment",
        "additionalProperties": [],
        "description": "",
        "className": "ShippingOrder",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "shipping-order",
        "key": "productQuantity",
        "type": "integer",
        "format": "",
        "title": "productQuantity",
        "additionalProperties": [],
        "description": "",
        "className": "ShippingOrder",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "shipping-order",
        "key": "productSku",
        "type": "string",
        "format": "",
        "title": "productSku",
        "additionalProperties": [],
        "description": "",
        "className": "ShippingOrder",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "shipping-order",
        "key": "reportItem",
        "type": "rel",
        "format": "report-item",
        "title": "reportItem",
        "additionalProperties": [],
        "description": "",
        "className": "ShippingOrder",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "shipping-order",
        "key": "status",
        "type": "integer",
        "format": "",
        "title": "status",
        "additionalProperties": [],
        "description": "",
        "className": "ShippingOrder",
        "isDb": true,
        "dbType": "int",
        "as": "status",
        "naeType": "status"
    },
    {
        "schema": "shipping-order",
        "key": "subscription",
        "type": "rel",
        "format": "subscription",
        "title": "subscription",
        "additionalProperties": [],
        "description": "",
        "className": "ShippingOrder",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "subscription",
        "key": "_ViewTitle",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Subscription",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "subscription",
        "key": "active",
        "type": "boolean",
        "format": "",
        "title": "Recurring active",
        "additionalProperties": [],
        "description": "",
        "className": "Subscription",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "subscription",
        "key": "activeAndCancelPayments",
        "type": "array",
        "format": "subscription-payment",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Subscription",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "subscription",
        "key": "activePayments",
        "type": "array",
        "format": "subscription-payment",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Subscription",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "subscription",
        "key": "activeStr",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Subscription",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "subscription",
        "key": "cancels",
        "type": "array",
        "format": "subscription-cancel",
        "title": "cancels",
        "additionalProperties": [
            {
                "mapped": "subscription"
            }
        ],
        "description": "",
        "className": "Subscription",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "subscription",
        "key": "customCancelEvent",
        "type": "string",
        "format": "",
        "title": "customCancelEvent",
        "additionalProperties": [],
        "description": "",
        "className": "Subscription",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "subscription",
        "key": "customer",
        "type": "rel",
        "format": "customer",
        "title": "Customer",
        "additionalProperties": [],
        "description": "",
        "className": "Subscription",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "subscription",
        "key": "customerProcessor",
        "type": "rel",
        "format": "customer",
        "title": "Customer processor",
        "additionalProperties": [],
        "description": "",
        "className": "Subscription",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "subscription",
        "key": "finishDate",
        "type": "string",
        "format": "date",
        "title": "Finish date",
        "additionalProperties": [],
        "description": "",
        "className": "Subscription",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "date"
    },
    {
        "schema": "subscription",
        "key": "hasTrial",
        "type": "boolean",
        "format": "",
        "title": "hasTrial",
        "additionalProperties": [],
        "description": "",
        "className": "Subscription",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "subscription",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Subscription",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "subscription",
        "key": "lifetime",
        "type": "boolean",
        "format": "",
        "title": "Lifetime",
        "additionalProperties": [],
        "description": "",
        "className": "Subscription",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "subscription",
        "key": "lifetimeUpgrade",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Subscription",
        "isDb": false,
        "dbType": "",
        "naeType": "number"
    },
    {
        "schema": "subscription",
        "key": "nextAfterSuccessPayDate",
        "type": "string",
        "format": "date-time",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Subscription",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "subscription",
        "key": "nextPayDate",
        "type": "string",
        "format": "date-time",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Subscription",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "subscription",
        "key": "nextPaymentDate",
        "type": "string",
        "format": "date-time",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Subscription",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "subscription",
        "key": "orderId",
        "type": "string",
        "format": "",
        "title": "orderId",
        "additionalProperties": [],
        "description": "",
        "className": "Subscription",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "subscription",
        "key": "orderIdFixed",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Subscription",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "subscription",
        "key": "paidPayments",
        "type": "array",
        "format": "subscription-payment",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Subscription",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "subscription",
        "key": "payments",
        "type": "array",
        "format": "subscription-payment",
        "title": "payments",
        "additionalProperties": [
            {
                "mapped": "subscription"
            }
        ],
        "description": "",
        "className": "Subscription",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "subscription",
        "key": "period",
        "type": "string",
        "format": "",
        "title": "Period",
        "additionalProperties": [],
        "description": "",
        "className": "Subscription",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "subscription",
        "key": "periodWithCouch",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Subscription",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "subscription",
        "key": "prices",
        "type": "array",
        "format": "subscription-price",
        "title": "prices",
        "additionalProperties": [
            {
                "mapped": "subscription"
            }
        ],
        "description": "",
        "className": "Subscription",
        "isDb": false,
        "dbType": "",
        "naeType": "array"
    },
    {
        "schema": "subscription",
        "key": "quizResult",
        "type": "rel",
        "format": "quiz-result",
        "title": "quizResult",
        "additionalProperties": [],
        "description": "",
        "className": "Subscription",
        "isDb": false,
        "dbType": "",
        "naeType": "object"
    },
    {
        "schema": "subscription",
        "key": "scopes",
        "type": "array",
        "format": "string",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Subscription",
        "isDb": false,
        "dbType": "",
        "naeType": "string_array"
    },
    {
        "schema": "subscription",
        "key": "source",
        "type": "string",
        "format": "",
        "title": "Source",
        "additionalProperties": [],
        "description": "",
        "className": "Subscription",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "subscription",
        "key": "sourceId",
        "type": "string",
        "format": "",
        "title": "Source ID",
        "additionalProperties": [],
        "description": "",
        "className": "Subscription",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "subscription",
        "key": "startDate",
        "type": "string",
        "format": "date",
        "title": "Start date",
        "additionalProperties": [],
        "description": "",
        "className": "Subscription",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "date"
    },
    {
        "schema": "subscription",
        "key": "startDateStr",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Subscription",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "subscription",
        "key": "status",
        "type": "string",
        "format": "",
        "title": "Status",
        "additionalProperties": [],
        "description": "",
        "className": "Subscription",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "subscription",
        "key": "subscriptionType",
        "type": "string",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "Subscription",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "subscription",
        "key": "total",
        "type": "number",
        "format": "float",
        "title": "Total",
        "additionalProperties": [],
        "description": "",
        "className": "Subscription",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "subscription-cancel",
        "key": "customerEmail",
        "type": "string",
        "format": "",
        "title": "Customer email",
        "additionalProperties": [],
        "description": "",
        "className": "SubscriptionCancel",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "subscription-cancel",
        "key": "date",
        "type": "string",
        "format": "date",
        "title": "Date",
        "additionalProperties": [],
        "description": "",
        "className": "SubscriptionCancel",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "subscription-cancel",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "SubscriptionCancel",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "subscription-cancel",
        "key": "reason",
        "type": "string",
        "format": "text",
        "title": "Reason",
        "additionalProperties": [],
        "description": "",
        "className": "SubscriptionCancel",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "subscription-cancel",
        "key": "subscription",
        "type": "rel",
        "format": "subscription",
        "title": "Subscription",
        "additionalProperties": [],
        "description": "",
        "className": "SubscriptionCancel",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "subscription-cancel",
        "key": "systemCancel",
        "type": "boolean",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "SubscriptionCancel",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "subscription-cancel-reason",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "SubscriptionCancelReason",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "subscription-cancel-reason",
        "key": "title",
        "type": "string",
        "format": "",
        "title": "Title",
        "additionalProperties": [],
        "description": "",
        "className": "SubscriptionCancelReason",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "subscription-cancel-request",
        "key": "action",
        "type": "string",
        "format": "",
        "title": "Action",
        "additionalProperties": [],
        "description": "",
        "className": "SubscriptionCancelRequest",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "subscription-cancel-request",
        "key": "createdAt",
        "type": "string",
        "format": "date-time",
        "title": "Created At",
        "additionalProperties": [],
        "description": "",
        "className": "SubscriptionCancelRequest",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "string"
    },
    {
        "schema": "subscription-cancel-request",
        "key": "description",
        "type": "string",
        "format": "text",
        "title": "Description",
        "additionalProperties": [],
        "description": "",
        "className": "SubscriptionCancelRequest",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "subscription-cancel-request",
        "key": "email",
        "type": "string",
        "format": "",
        "title": "Email",
        "additionalProperties": [],
        "description": "",
        "className": "SubscriptionCancelRequest",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "subscription-cancel-request",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "SubscriptionCancelRequest",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "subscription-cancel-request",
        "key": "status",
        "type": "integer",
        "format": "",
        "title": "Status",
        "additionalProperties": [],
        "description": "",
        "className": "SubscriptionCancelRequest",
        "isDb": true,
        "dbType": "int",
        "as": "status",
        "naeType": "status"
    },
    {
        "schema": "subscription-cancel-request",
        "key": "subscription",
        "type": "rel",
        "format": "subscription",
        "title": "Subscription",
        "additionalProperties": [],
        "description": "",
        "className": "SubscriptionCancelRequest",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "subscription-cancel-request",
        "key": "topic1",
        "type": "string",
        "format": "",
        "title": "Topic1",
        "additionalProperties": [],
        "description": "",
        "className": "SubscriptionCancelRequest",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "subscription-cancel-request",
        "key": "topic2",
        "type": "string",
        "format": "",
        "title": "Topic2",
        "additionalProperties": [],
        "description": "",
        "className": "SubscriptionCancelRequest",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "subscription-payment",
        "key": "date",
        "type": "string",
        "format": "date",
        "title": "Date",
        "additionalProperties": [],
        "description": "",
        "className": "SubscriptionPayment",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "date"
    },
    {
        "schema": "subscription-payment",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "SubscriptionPayment",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "subscription-payment",
        "key": "paidDate",
        "type": "string",
        "format": "date",
        "title": "Paid date",
        "additionalProperties": [],
        "description": "",
        "className": "SubscriptionPayment",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "date"
    },
    {
        "schema": "subscription-payment",
        "key": "status",
        "type": "string",
        "format": "",
        "title": "Status",
        "additionalProperties": [],
        "description": "",
        "className": "SubscriptionPayment",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "subscription-payment",
        "key": "subscription",
        "type": "rel",
        "format": "subscription",
        "title": "Subscription",
        "additionalProperties": [],
        "description": "",
        "className": "SubscriptionPayment",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "subscription-payment",
        "key": "total",
        "type": "number",
        "format": "float",
        "title": "Total",
        "additionalProperties": [],
        "description": "",
        "className": "SubscriptionPayment",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "subscription-price",
        "key": "dateFrom",
        "type": "string",
        "format": "date",
        "title": "Date from",
        "additionalProperties": [],
        "description": "",
        "className": "SubscriptionPrice",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "subscription-price",
        "key": "dateTo",
        "type": "string",
        "format": "date",
        "title": "Date to",
        "additionalProperties": [],
        "description": "",
        "className": "SubscriptionPrice",
        "isDb": true,
        "dbType": "date",
        "naeType": "date"
    },
    {
        "schema": "subscription-price",
        "key": "description",
        "type": "string",
        "format": "text",
        "title": "Description",
        "additionalProperties": [],
        "description": "",
        "className": "SubscriptionPrice",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "subscription-price",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "SubscriptionPrice",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "subscription-price",
        "key": "subscription",
        "type": "rel",
        "format": "subscription",
        "title": "Subscription",
        "additionalProperties": [],
        "description": "",
        "className": "SubscriptionPrice",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "subscription-price",
        "key": "total",
        "type": "number",
        "format": "float",
        "title": "Total",
        "additionalProperties": [],
        "description": "",
        "className": "SubscriptionPrice",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "subscription-support-report",
        "key": "active",
        "type": "boolean",
        "format": "",
        "title": "Active",
        "additionalProperties": [],
        "description": "",
        "className": "SubscriptionSupportReport",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "subscription-support-report",
        "key": "cancelReason",
        "type": "string",
        "format": "text",
        "title": "Cancellation Reason",
        "additionalProperties": [],
        "description": "",
        "className": "SubscriptionSupportReport",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "subscription-support-report",
        "key": "cancelledInt",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "SubscriptionSupportReport",
        "isDb": false,
        "dbType": "",
        "naeType": "number"
    },
    {
        "schema": "subscription-support-report",
        "key": "chargeBack",
        "type": "boolean",
        "format": "",
        "title": "Chargeback",
        "additionalProperties": [],
        "description": "",
        "className": "SubscriptionSupportReport",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "subscription-support-report",
        "key": "chargeBackInt",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "SubscriptionSupportReport",
        "isDb": false,
        "dbType": "",
        "naeType": "number"
    },
    {
        "schema": "subscription-support-report",
        "key": "customerEmail",
        "type": "string",
        "format": "",
        "title": "Email",
        "additionalProperties": [],
        "description": "",
        "className": "SubscriptionSupportReport",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "subscription-support-report",
        "key": "finishDate",
        "type": "string",
        "format": "date",
        "title": "Finish Date",
        "additionalProperties": [],
        "description": "",
        "className": "SubscriptionSupportReport",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "date"
    },
    {
        "schema": "subscription-support-report",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "SubscriptionSupportReport",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "subscription-support-report",
        "key": "language",
        "type": "string",
        "format": "",
        "title": "Language",
        "additionalProperties": [],
        "description": "",
        "className": "SubscriptionSupportReport",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "subscription-support-report",
        "key": "lifetime",
        "type": "boolean",
        "format": "",
        "title": "Lifetime",
        "additionalProperties": [],
        "description": "",
        "className": "SubscriptionSupportReport",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "subscription-support-report",
        "key": "orderId",
        "type": "string",
        "format": "",
        "title": "Order ID",
        "additionalProperties": [],
        "description": "",
        "className": "SubscriptionSupportReport",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "subscription-support-report",
        "key": "period",
        "type": "string",
        "format": "",
        "title": "Period",
        "additionalProperties": [],
        "description": "",
        "className": "SubscriptionSupportReport",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "subscription-support-report",
        "key": "periodLt",
        "type": "string",
        "format": "",
        "title": "Period + LT",
        "additionalProperties": [],
        "description": "",
        "className": "SubscriptionSupportReport",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "subscription-support-report",
        "key": "refundPercent",
        "type": "number",
        "format": "float",
        "title": "refundPercent",
        "additionalProperties": [],
        "description": "",
        "className": "SubscriptionSupportReport",
        "isDb": true,
        "dbType": "double",
        "naeType": "float"
    },
    {
        "schema": "subscription-support-report",
        "key": "refunded",
        "type": "boolean",
        "format": "",
        "title": "Refunded",
        "additionalProperties": [],
        "description": "",
        "className": "SubscriptionSupportReport",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "subscription-support-report",
        "key": "refundedInt",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "SubscriptionSupportReport",
        "isDb": false,
        "dbType": "",
        "naeType": "number"
    },
    {
        "schema": "subscription-support-report",
        "key": "slug",
        "type": "string",
        "format": "",
        "title": "Funnel",
        "additionalProperties": [],
        "description": "",
        "className": "SubscriptionSupportReport",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "subscription-support-report",
        "key": "sourceId",
        "type": "string",
        "format": "",
        "title": "Source ID",
        "additionalProperties": [],
        "description": "",
        "className": "SubscriptionSupportReport",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "subscription-support-report",
        "key": "startDate",
        "type": "string",
        "format": "date",
        "title": "Start Date",
        "additionalProperties": [],
        "description": "",
        "className": "SubscriptionSupportReport",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "date"
    },
    {
        "schema": "subscription-support-report",
        "key": "type",
        "type": "string",
        "format": "",
        "title": "Type",
        "additionalProperties": [],
        "description": "",
        "className": "SubscriptionSupportReport",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "user",
        "key": "allowedIp",
        "type": "string",
        "format": "text",
        "title": "IP",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "user",
        "key": "authSourceId",
        "type": "integer",
        "format": "",
        "title": "authSourceId",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "user",
        "key": "disabled",
        "type": "boolean",
        "format": "",
        "title": "Disabled",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "user",
        "key": "email",
        "type": "string",
        "format": "",
        "title": "Email",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "user",
        "key": "firstName",
        "type": "string",
        "format": "",
        "title": "First name",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "user",
        "key": "fullName",
        "type": "string",
        "format": "",
        "title": "Full name",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "user",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "user",
        "key": "lastName",
        "type": "string",
        "format": "",
        "title": "Last name",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "user",
        "key": "login",
        "type": "string",
        "format": "",
        "title": "Login",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "user",
        "key": "mailSignature",
        "type": "string",
        "format": "text",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "longtext",
        "naeType": "text"
    },
    {
        "schema": "user",
        "key": "password",
        "type": "string",
        "format": "",
        "title": "Password",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "user",
        "key": "permissionGroup",
        "type": "string",
        "format": "",
        "title": "Permissions",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "varchar",
        "enum": [
            {
                "label": "Admin",
                "value": "admin",
                "color": "slate"
            },
            {
                "label": "Support",
                "value": "support",
                "color": "slate"
            },
            {
                "label": "Support Head",
                "value": "support_head",
                "color": "slate"
            },
            {
                "label": "Product Lead",
                "value": "product_lead",
                "color": "slate"
            },
            {
                "label": "Media Buyer",
                "value": "media_buyer",
                "color": "slate"
            },
            {
                "label": "Tiktok",
                "value": "tiktok",
                "color": "slate"
            },
            {
                "label": "Google",
                "value": "google",
                "color": "slate"
            },
            {
                "label": "Newsletter",
                "value": "newsletter",
                "color": "slate"
            },
            {
                "label": "Additional subs",
                "value": "additionalsubs",
                "color": "slate"
            },
            {
                "label": "None",
                "value": "none",
                "color": "slate"
            },
            {
                "label": "DEV",
                "value": "dev",
                "color": "slate"
            },
            {
                "label": "Monitoring",
                "value": "monitoring",
                "color": "slate"
            }
        ],
        "naeType": "enum_text"
    },
    {
        "schema": "user",
        "key": "phone",
        "type": "string",
        "format": "",
        "title": "Phone",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "user",
        "key": "plainPassword",
        "type": "string",
        "format": "password",
        "title": "Password",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": false,
        "dbType": "",
        "naeType": "string"
    },
    {
        "schema": "user",
        "key": "position",
        "type": "string",
        "format": "",
        "title": "Position",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "user",
        "key": "scopes",
        "type": "array",
        "format": "string",
        "title": "Scopes",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": false,
        "dbType": "",
        "naeType": "string_array"
    },
    {
        "schema": "user",
        "key": "superUser",
        "type": "boolean",
        "format": "",
        "title": "Super",
        "additionalProperties": [],
        "description": "",
        "className": "User",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "webhook-processing",
        "key": "createdAt",
        "type": "string",
        "format": "datetime",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "WebhookProcessing",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "webhook-processing",
        "key": "creator",
        "type": "rel",
        "format": "user",
        "title": "creator",
        "additionalProperties": [],
        "description": "",
        "className": "WebhookProcessing",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "webhook-processing",
        "key": "doer",
        "type": "rel",
        "format": "user",
        "title": "doer",
        "additionalProperties": [],
        "description": "",
        "className": "WebhookProcessing",
        "isDb": true,
        "dbType": "int",
        "naeType": "object"
    },
    {
        "schema": "webhook-processing",
        "key": "id",
        "type": "integer",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "WebhookProcessing",
        "isDb": true,
        "dbType": "int",
        "naeType": "number"
    },
    {
        "schema": "webhook-processing",
        "key": "needProcess",
        "type": "boolean",
        "format": "",
        "title": "needProcess",
        "additionalProperties": [],
        "description": "",
        "className": "WebhookProcessing",
        "isDb": true,
        "dbType": "tinyint",
        "naeType": "bool"
    },
    {
        "schema": "webhook-processing",
        "key": "skipValidation",
        "type": "boolean",
        "format": "",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "WebhookProcessing",
        "isDb": false,
        "dbType": "",
        "naeType": "bool"
    },
    {
        "schema": "webhook-processing",
        "key": "type",
        "type": "string",
        "format": "",
        "title": "type",
        "additionalProperties": [],
        "description": "",
        "className": "WebhookProcessing",
        "isDb": true,
        "dbType": "varchar",
        "naeType": "string"
    },
    {
        "schema": "webhook-processing",
        "key": "updatedAt",
        "type": "string",
        "format": "datetime",
        "title": "",
        "additionalProperties": [],
        "description": "",
        "className": "WebhookProcessing",
        "isDb": true,
        "dbType": "datetime",
        "naeType": "datetime"
    },
    {
        "schema": "webhook-processing",
        "key": "whBody",
        "type": "array",
        "format": "string",
        "title": "whBody",
        "additionalProperties": [],
        "description": "",
        "className": "WebhookProcessing",
        "isDb": true,
        "dbType": "json",
        "naeType": "string_array"
    }
]