
import classNames from 'classnames';
import React, { Fragment, useState } from 'react'
import { Table, Th, Td } from '@newageerp/v3.bundles.layout-bundle';
import { useTranslation } from 'react-i18next';
import { numberFormat } from '@newageerp/v3.bundles.utils-bundle';
import { DashboardGrossV2borderRight } from '../DashboardGrossV2';
import { useTemplatesCore } from '@newageerp/v3.templates.templates-core';
import { checkAdmin } from '../../../_generated/_custom/config/NaeSPermissions';
import { Compact, CompactRow, FieldInput, FieldLabel, FieldSelectMulti } from '@newageerp/v3.bundles.form-bundle';
import { useLocalStorage } from '@newageerp/v3.bundles.hooks-bundle';

type Props = {
    groupBy: string
    showExtra: boolean
    statsExtra: any
    stats: any
}

const GrossTableOptions = [
    {
        value: 'initial_1',
        label: 'Initial Gross Revenue'
    },
    {
        value: 'initial_split',
        label: 'Initial Gross Revenue Split'
    },
    {
        value: 'upsell_split',
        label: 'Upsell Split'
    },
    {
        value: 'subscriptions',
        label: 'Subscriptions'
    },
    {
        value: 'analytics',
        label: 'Analytics'
    },
    {
        value: 'cancelations',
        label: 'Cancelations'
    },
    {
        value: 'ad_spend',
        label: 'Ad spend'
    },
    {
        value: 'aov',
        label: 'AOV'
    },
    {
        value: 'roas',
        label: 'ROAS'
    },
    {
        value: 'recurring',
        label: 'Recurring'
    },
    {
        value: 'performance',
        label: 'Performance'
    }
]

export default function GrossV2MainTable({ groupBy, showExtra, statsExtra, stats }: Props) {
    const { t } = useTranslation();
    const { userState } = useTemplatesCore();

    const [quickSearch, setQuickSearch] = useState('');

    const [grossTableSections, setGrossTableSections] = useLocalStorage(`GrossV2MainTableSections_${groupBy}`, []);
    const shouldShowTableSection = (section: string) => grossTableSections.length === 0 || grossTableSections.indexOf(section) >= 0;

    const isAdmin = checkAdmin(userState);

    const _display = {
        initial: shouldShowTableSection('initial_1'),
        initial_split: shouldShowTableSection('initial_split'),
        upsell_split: shouldShowTableSection('upsell_split'),
        subscriptions: shouldShowTableSection('subscriptions'),
        analytics: shouldShowTableSection('analytics') && (groupBy === 'funnel' || groupBy === 'funnel_part' || groupBy === 'country' || groupBy === 'ad_source' || groupBy === 'date'),

        cancelations: groupBy === 'funnel' && shouldShowTableSection('cancelations'),
        adSpend: showExtra && shouldShowTableSection('ad_spend'),
        aov: shouldShowTableSection('aov'),
        roas: showExtra && shouldShowTableSection('roas'),
        recurring: isAdmin && shouldShowTableSection('recurring'),
        grossPerformance: showExtra && isAdmin && shouldShowTableSection('performance')
    }

    const _classNames = {
        cpa: DashboardGrossV2borderRight,
        aov: classNames({
            [DashboardGrossV2borderRight]: !_display.roas
        }),
        roas: DashboardGrossV2borderRight,
        recurring: DashboardGrossV2borderRight
    }

    const rowsMap = statsExtra.dates.filter((s: string) => {
        if (!quickSearch) {
            return true;
        }
        return s.toLowerCase().indexOf(quickSearch.toLowerCase()) >= 0;
    });

    return (
        <div className='space-y-4'>
            <Compact>
                <CompactRow
                    label={<FieldLabel>{t('Show sections')}</FieldLabel>}
                    control={
                        <FieldSelectMulti
                            value={grossTableSections}
                            onChange={setGrossTableSections}
                            options={GrossTableOptions}
                            asString={true}
                            allowSelectAll={true}
                        />
                    }
                />
            </Compact>
            <div className='overflow-x-auto'>
                <Table
                    id='gross-pl-export'
                    thead={<thead>
                        <tr>
                            <Td className={classNames('sticky left-0 bg-white', DashboardGrossV2borderRight)}>
                                <FieldInput
                                    value={quickSearch}
                                    onChange={(e) => setQuickSearch(e.target.value)}
                                    placeholder='Filter'
                                    iconName='search'
                                />
                            </Td>
                            {_display.initial &&
                                <Th textAlignment={classNames('text-right', DashboardGrossV2borderRight)}>{t('Initial Gross Revenue')}</Th>
                            }
                            {_display.initial_split &&
                                <Fragment>
                                    <Th textAlignment='text-right'>{t('Initial (subsciptions)')}</Th>
                                    <Th textAlignment='text-right'>{t('Initial (one-time plan)')}</Th>
                                    <Th textAlignment='text-right'>{t('Initial (upsells)')}</Th>
                                    <Th textAlignment={classNames('text-right', DashboardGrossV2borderRight)}>{t('Upsell rate')}</Th>
                                </Fragment>
                            }
                            {_display.upsell_split &&
                                <Fragment>
                                    <Th textAlignment='text-right'>{t('UP by client')}</Th>
                                    <Th textAlignment='text-right'>{t('UP (e-book) by client')}</Th>
                                    <Th textAlignment='text-right'>{t('UP (lifetime) by client')}</Th>
                                    <Th textAlignment={classNames('text-right', DashboardGrossV2borderRight)}>{t('Lifetime %')}</Th>
                                </Fragment>
                            }
                            {_display.subscriptions && <Fragment>
                                <Th textAlignment='text-right'>{t('Subscriptions')}</Th>

                                {statsExtra.plans.map((p: string, idx: number) => {
                                    return (
                                        <Th key={`plan-${p}`} textAlignment={
                                            classNames(
                                                'text-right',
                                                (statsExtra.plans.length === idx + 1) && DashboardGrossV2borderRight
                                            )
                                        }>{p}</Th>
                                    )
                                })}
                            </Fragment>}

                            {_display.analytics && <Fragment>
                                <Th textAlignment='text-right'>{t('Advertorial')}</Th>
                                <Th textAlignment='text-right'>{t('Landing')}</Th>
                                <Th textAlignment='text-right'>{t('Start quiz')}</Th>
                                <Th textAlignment='text-right'>{t('Results page')}</Th>
                                <Th textAlignment='text-right'>{t('Left email')}</Th>
                                <Th textAlignment='text-right'>{t('Checkout')}</Th>
                                <Th textAlignment='text-right'>{t('Payment')}</Th>
                                <Th textAlignment='text-right'>{t('Purchase')}</Th>
                                <Th textAlignment='text-right' title="CR from landing">{t('CR L')}</Th>
                                <Th textAlignment={classNames('text-right')} title="CR from checkout">{t('CR C')}</Th>
                                <Th textAlignment={classNames('text-right', DashboardGrossV2borderRight)} title="CR from payment window">{t('CR P')}</Th>
                            </Fragment>}

                            {_display.cancelations &&
                                <Fragment>
                                    <Th textAlignment='text-right'>{t('Cancelations')}</Th>

                                    <Th textAlignment='text-right'>{t('1m')}</Th>
                                    <Th textAlignment='text-right'>{t('3m')}</Th>
                                    <Th textAlignment={classNames('text-right', DashboardGrossV2borderRight)}>{t('6m')}</Th>

                                    <Th textAlignment='text-right'>{t('Churn')}</Th>

                                    <Th textAlignment={classNames('text-right', DashboardGrossV2borderRight)}>{t('Refunds')}</Th>
                                </Fragment>
                            }

                            {_display.adSpend &&
                                <Fragment>
                                    <Th textAlignment='text-right'>{t('Ad spend')}</Th>
                                    <Th textAlignment='text-right' className={_classNames.cpa}>{t('CPA')}</Th>
                                </Fragment>
                            }
                            {_display.aov &&
                                <Th textAlignment='text-right' className={_classNames.aov}>{t('AOV')}</Th>
                            }
                            {_display.roas &&
                                <Th textAlignment='text-right' className={_classNames.roas}>{t('ROAS')}</Th>
                            }

                            {_display.recurring && <Th textAlignment='text-right' className={_classNames.recurring}>{t('Recurring revenue')}</Th>}

                            {_display.grossPerformance &&
                                <Th textAlignment='text-right'>{t('Gross Performance')}</Th>
                            }

                        </tr>
                    </thead>}
                    tbody={
                        <tbody>
                            {rowsMap.map((dt: string, idx: number) => {
                                const upsellRate = stats[dt].initialGrossValue > 0 ? stats[dt].initialUpsellsValue / stats[dt].initialGrossValue * 100 : 0;
                                const upByClient = stats[dt].subscriptions > 0 ? stats[dt].initialUpsellsValue / stats[dt].subscriptions : 0;
                                const upEbookByClient = stats[dt].subscriptions > 0 ? stats[dt].initialUpsellsEbookValue / stats[dt].subscriptions : 0;
                                const upLifetimeByClient = stats[dt].subscriptions > 0 ? stats[dt].initialUpsellsLifetimeValue / stats[dt].subscriptions : 0;
                                const oneTimeRate = stats[dt].subscriptions > 0 ? stats[dt].oneTime / stats[dt].subscriptions * 100 : 0;

                                const roas = stats[dt].adSpend > 0 ? stats[dt].initialGrossValue / stats[dt].adSpend : 0;
                                const cpa = stats[dt].subscriptions ? stats[dt].adSpend / stats[dt].subscriptions : 0;
                                const aov = stats[dt].subscriptions ? stats[dt].initialGrossValue / stats[dt].subscriptions : 0;

                                const grossPl = stats[dt].initialGrossValue - stats[dt].adSpend + stats[dt].recurring;

                                let viewsCr = stats[dt].subscriptions > 0 && stats[dt].views.PageView > 0 ? (stats[dt].subscriptions / stats[dt].views.PageView * 100) : 0;
                                let checkoutCr = stats[dt].subscriptions > 0 && stats[dt].views.Checkout > 0 ? (stats[dt].subscriptions / stats[dt].views.Checkout * 100) : 0;
                                let pwCr = stats[dt].subscriptions > 0 && stats[dt].views.PaymentWindow > 0 ? (stats[dt].subscriptions / stats[dt].views.PaymentWindow * 100) : 0;

                                return <tr key={`row-${dt}-${idx}`} className={classNames({ 'total-row font-medium': dt === '___all' })}>
                                    <Td className={classNames('whitespace-nowrap sticky left-0 bg-white', DashboardGrossV2borderRight)}>
                                        {dt === '___all' ? 'Total' : dt}
                                    </Td>
                                    {_display.initial &&
                                        <Td textAlignment={classNames('whitespace-nowrap text-right', DashboardGrossV2borderRight)}>
                                            {numberFormat({
                                                userInput: stats[dt].initialGrossValue,
                                                decimals: 2,
                                                decPoint: '.',
                                                thousandsSep: ' '
                                            })}
                                        </Td>
                                    }
                                    {_display.initial_split && <Fragment>
                                        <Td textAlignment='whitespace-nowrap text-right'>
                                            {numberFormat({
                                                userInput: stats[dt].initialSubscribersGrossValue,
                                                decimals: 2,
                                                decPoint: '.',
                                                thousandsSep: ' '
                                            })}
                                        </Td>
                                        <Td textAlignment='whitespace-nowrap text-right'>
                                            {numberFormat({
                                                userInput: stats[dt].initialOneTimeGrossValue,
                                                decimals: 2,
                                                decPoint: '.',
                                                thousandsSep: ' '
                                            })}
                                        </Td>
                                        <Td textAlignment='whitespace-nowrap text-right'>
                                            {numberFormat({
                                                userInput: stats[dt].initialUpsellsValue,
                                                decimals: 2,
                                                decPoint: '.',
                                                thousandsSep: ' '
                                            })}
                                        </Td>
                                        <Td textAlignment={classNames('whitespace-nowrap text-right', DashboardGrossV2borderRight)}>
                                            {numberFormat({
                                                userInput: upsellRate,
                                                decimals: 2,
                                                decPoint: '.',
                                                thousandsSep: ' '
                                            })}%
                                        </Td>
                                    </Fragment>}
                                    {_display.upsell_split && <Fragment>
                                        <Td textAlignment='whitespace-nowrap text-right'>
                                            {numberFormat({
                                                userInput: upByClient,
                                                decimals: 2,
                                                decPoint: '.',
                                                thousandsSep: ' '
                                            })}
                                        </Td>
                                        <Td textAlignment='whitespace-nowrap text-right'>
                                            {numberFormat({
                                                userInput: upEbookByClient,
                                                decimals: 2,
                                                decPoint: '.',
                                                thousandsSep: ' '
                                            })}
                                        </Td>
                                        <Td textAlignment='whitespace-nowrap text-right'>
                                            {numberFormat({
                                                userInput: upLifetimeByClient,
                                                decimals: 2,
                                                decPoint: '.',
                                                thousandsSep: ' '
                                            })}
                                        </Td>
                                        <Td textAlignment={classNames('whitespace-nowrap text-right', DashboardGrossV2borderRight)}>
                                            {numberFormat({
                                                userInput: oneTimeRate,
                                                decimals: 2,
                                                decPoint: '.',
                                                thousandsSep: ' '
                                            })}%
                                        </Td>

                                    </Fragment>}

                                    {_display.subscriptions && <Fragment>
                                        <Td textAlignment='whitespace-nowrap text-right'>
                                            {numberFormat({
                                                userInput: stats[dt].subscriptions,
                                                decimals: 0,
                                                decPoint: '.',
                                                thousandsSep: ' '
                                            })}
                                        </Td>

                                        {statsExtra.plans.map((p: string, idx: number) => {
                                            return (
                                                <Td key={`plan-${p}`} textAlignment={
                                                    classNames(
                                                        'whitespace-nowrap text-right',
                                                        (statsExtra.plans.length === idx + 1) && DashboardGrossV2borderRight
                                                    )
                                                }>
                                                    {numberFormat({
                                                        userInput: stats[dt].subscriptions_plans[p],
                                                        decimals: 0,
                                                        decPoint: '.',
                                                        thousandsSep: ' '
                                                    })}
                                                </Td>
                                            )
                                        })}

                                    </Fragment>}


                                    {_display.analytics && <Fragment>
                                        <Td textAlignment='whitespace-nowrap text-right'>
                                            {numberFormat({
                                                userInput: stats[dt].views.Advertorial,
                                                decimals: 0,
                                                decPoint: '.',
                                                thousandsSep: ' '
                                            })}
                                        </Td>
                                        <Td textAlignment='whitespace-nowrap text-right'>
                                            {numberFormat({
                                                userInput: stats[dt].views.PageView,
                                                decimals: 0,
                                                decPoint: '.',
                                                thousandsSep: ' '
                                            })}
                                        </Td>
                                        <Td textAlignment='whitespace-nowrap text-right'>
                                            {numberFormat({
                                                userInput: stats[dt].views['Start quiz'],
                                                decimals: 0,
                                                decPoint: '.',
                                                thousandsSep: ' '
                                            })}
                                        </Td>
                                        <Td textAlignment='whitespace-nowrap text-right'>
                                            {numberFormat({
                                                userInput: stats[dt].views['Results page'],
                                                decimals: 0,
                                                decPoint: '.',
                                                thousandsSep: ' '
                                            })}
                                        </Td>
                                        <Td textAlignment='whitespace-nowrap text-right'>
                                            {numberFormat({
                                                userInput: stats[dt].views.LeftEmail,
                                                decimals: 0,
                                                decPoint: '.',
                                                thousandsSep: ' '
                                            })}
                                        </Td>
                                        <Td textAlignment='whitespace-nowrap text-right'>
                                            {numberFormat({
                                                userInput: stats[dt].views.Checkout,
                                                decimals: 0,
                                                decPoint: '.',
                                                thousandsSep: ' '
                                            })}
                                        </Td>
                                        <Td textAlignment='whitespace-nowrap text-right'>
                                            {numberFormat({
                                                userInput: stats[dt].views.PaymentWindow,
                                                decimals: 0,
                                                decPoint: '.',
                                                thousandsSep: ' '
                                            })}
                                        </Td>

                                        <Td textAlignment='whitespace-nowrap text-right'>
                                            {numberFormat({
                                                userInput: stats[dt].subscriptions,
                                                decimals: 0,
                                                decPoint: '.',
                                                thousandsSep: ' '
                                            })}
                                        </Td>

                                        <Td textAlignment={classNames('whitespace-nowrap text-right')}>
                                            {numberFormat({
                                                userInput: viewsCr,
                                                decimals: 2,
                                                decPoint: '.',
                                                thousandsSep: ' '
                                            })}%
                                        </Td>

                                        <Td textAlignment={classNames('whitespace-nowrap text-right', )}>
                                            {numberFormat({
                                                userInput: checkoutCr,
                                                decimals: 2,
                                                decPoint: '.',
                                                thousandsSep: ' '
                                            })}%
                                        </Td>
                                        <Td textAlignment={classNames('whitespace-nowrap text-right', DashboardGrossV2borderRight)}>
                                            {numberFormat({
                                                userInput: pwCr,
                                                decimals: 2,
                                                decPoint: '.',
                                                thousandsSep: ' '
                                            })}%
                                        </Td>
                                    </Fragment>}

                                    {_display.cancelations &&
                                        <Fragment>
                                            <Td textAlignment='whitespace-nowrap text-right'>
                                                {numberFormat({
                                                    userInput: stats[dt].drops_ok,
                                                    decimals: 0,
                                                    decPoint: '.',
                                                    thousandsSep: ' '
                                                })}
                                            </Td>

                                            <Td textAlignment='whitespace-nowrap text-right'>
                                                {numberFormat({
                                                    userInput: stats[dt].drops_ok_plans['1m'],
                                                    decimals: 0,
                                                    decPoint: '.',
                                                    thousandsSep: ' '
                                                })}
                                            </Td>
                                            <Td textAlignment='whitespace-nowrap text-right'>
                                                {numberFormat({
                                                    userInput: stats[dt].drops_ok_plans['3m'],
                                                    decimals: 0,
                                                    decPoint: '.',
                                                    thousandsSep: ' '
                                                })}
                                            </Td>
                                            <Td textAlignment={classNames('whitespace-nowrap text-right', DashboardGrossV2borderRight)}>
                                                {numberFormat({
                                                    userInput: stats[dt].drops_ok_plans['6m'],
                                                    decimals: 0,
                                                    decPoint: '.',
                                                    thousandsSep: ' '
                                                })}
                                            </Td>

                                            <Td textAlignment='whitespace-nowrap text-right'>
                                                {numberFormat({
                                                    userInput: (stats[dt].drops_ok / (stats[dt].subscriptions - stats[dt].oneTime)) * 100,
                                                    decimals: 2,
                                                    decPoint: '.',
                                                    thousandsSep: ' '
                                                })}%
                                            </Td>

                                            <Td textAlignment={classNames('whitespace-nowrap text-right', DashboardGrossV2borderRight)}>
                                                {numberFormat({
                                                    userInput: stats[dt].refundsValue,
                                                    decimals: 2,
                                                    decPoint: '.',
                                                    thousandsSep: ' '
                                                })}
                                            </Td>
                                        </Fragment>
                                    }


                                    {_display.adSpend &&
                                        <Fragment>
                                            <Td textAlignment='whitespace-nowrap text-right'>
                                                {numberFormat({
                                                    userInput: stats[dt].adSpend,
                                                    decimals: 2,
                                                    decPoint: '.',
                                                    thousandsSep: ' '
                                                })}
                                            </Td>
                                            <Td textAlignment='whitespace-nowrap text-right' className={_classNames.cpa}>
                                                {numberFormat({
                                                    userInput: cpa,
                                                    decimals: 2,
                                                    decPoint: '.',
                                                    thousandsSep: ' '
                                                })}
                                            </Td>
                                        </Fragment>
                                    }
                                    {_display.aov &&
                                        <Td textAlignment='whitespace-nowrap text-right' className={_classNames.aov}>
                                            {numberFormat({
                                                userInput: aov,
                                                decimals: 2,
                                                decPoint: '.',
                                                thousandsSep: ' '
                                            })}
                                        </Td>
                                    }
                                    {_display.roas &&
                                        <Td textAlignment='whitespace-nowrap text-right' className={_classNames.roas}>
                                            {numberFormat({
                                                userInput: roas,
                                                decimals: 2,
                                                decPoint: '.',
                                                thousandsSep: ' '
                                            })}
                                        </Td>
                                    }
                                    {_display.recurring &&
                                        <Td textAlignment='whitespace-nowrap text-right' className={_classNames.recurring}>
                                            {numberFormat({
                                                userInput: stats[dt].recurring,
                                                decimals: 2,
                                                decPoint: '.',
                                                thousandsSep: ' '
                                            })}
                                        </Td>

                                    }

                                    {_display.grossPerformance &&
                                        <Td textAlignment='whitespace-nowrap text-right'>
                                            {numberFormat({
                                                userInput: grossPl,
                                                decimals: 2,
                                                decPoint: '.',
                                                thousandsSep: ' '
                                            })}
                                        </Td>
                                    }
                                </tr>
                            })}
                        </tbody>
                    }
                />
            </div>
        </div>
    )
}
